const metisMenu = [
  {
    id: 1,
    icon: "icon-speedometer",
    label: "Dashboard",
    to: "/dashboard12",
  },

  // WORKSHEET SECTION
  // {
  //   id: "worksheets",
  //   label: "Worksheets",
  // },
  // {
  //   id: 2,
  //   icon: "fa fa-history",
  //   label: "Histori Unit Kerja",
  //   to: "/histori_unit_kerja",
  // },
  // {
  //   id: 2,
  //   icon: "fa fa-list-ol",
  //   label: "Seluruh Tugas",
  //   to: "/tugas",
  // },
  // {
  //   id: 4,
  //   icon: "fa fa-list-ol",
  //   label: "Approval Tugas",
  //   to: "/",
  //   content: [
  //     {
  //       id: 4.1,
  //       label: "Diajukan",
  //       to: "/approval",
  //     },
  //     {
  //       id: 4.2,
  //       label: "Dikerjakan",
  //       to: "/app-project-list",
  //     },
  //   ],
  // },

  // PERIODE SECTION
  // {
  // 	"id": 'periode',
  // 	"label": "Periode"
  // }, {
  // 	"id": 5,
  // 	"icon": "fa fa-calendar",
  // 	"label": "Periode",
  // 	"to": "/periode"
  // },

  // REPORT SECTION
  {
    id: "laporan",
    label: "Laporan",
  },
  {
    id: 3,
    icon: "fa fa-sitemap",
    label: "Laporan Harian",
    to: "/laporan/harian",
  },

  // MASTER SECTION
  {
    id: "master",
    label: "Master",
  },
  // {
  //   id: 8,
  //   icon: "fa fa-sitemap",
  //   label: "Struktur Organisasi",
  //   to: "/struktur-organisasi",
  // },
  // {
  // 	'id': 7,
  // 	'icon': 'fa fa-user',
  // 	'label': 'Direksi',
  // 	"to": '/direksi'
  // },
  {
    id: 4,
    icon: "fa fa-group",
    label: "Pegawai",
    to: "/pegawai",
  },
  {
    id: 5,
    icon: "fa fa-university",
    label: "Kantor",
    to: "/kantor",
  },
  {
    id: 6,
    icon: "fa fa-id-card",
    label: "Jabatan",
    to: "/jabatan",
  },
  {
    id: 7,
    icon: "fa fa-id-card",
    label: "Skill",
    to: "/skill",
  },
  {
    id: 8,
    icon: "fa fa-id-card",
    label: "Kesalahan",
    to: "/kesalahan",
  },
  {
    id: 9,
    icon: "fa fa-id-card",
    label: "Hari Libur",
    to: "/hari-libur",
  },
  {
    id: 10,
    icon: "fa fa-id-card",
    label: "Judul Tugas Rutin",
    to: "/judul-tugas-rutin",
  },

  // SAM KREDIT SECTION
  // {
  //   id: "sam_kredit",
  //   label: "SAM Kredit",
  // },
  // {
  //   id: 11,
  //   icon: "fa fa-group",
  //   label: "Aktivitas AO",
  //   to: "/aktivitas-ao",
  // },
  // {
  //   id: 12,
  //   icon: "fa fa-group",
  //   label: "Logs SAM Kredit",
  //   to: "/logs/sk",
  // },
];

export default metisMenu;
