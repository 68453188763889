import React, { Component } from "react";
import { Link } from "react-router-dom";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Multiselect } from "multiselect-react-dropdown";
import { Button, FormGroup } from "react-bootstrap";
import { api, apiWs } from "../../configs/axios";
import { defaultLimit, toastMessageCall, encrypt } from "../../helpers/Utils";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import "flatpickr/dist/themes/airbnb.css";
import Flatpickr from "react-flatpickr";
import "../../FPOverride.css";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getCookie } from "../../helpers/CookieManager";
import LoadingScreen from 'react-loading-screen';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});


class UpdateTugas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      judul: "",
      kategori: "",
      jenis: "",
      start: "",
      end: "",
      tugas: "",
      pegawai: [],
      atasan: "",
      data_jenis: [],
      data_pegawai: [],
      data_atasan: [],
      expired: "",
      nominal: "",
      file: "",
      isLoading: false,
      session_credential: {},
      atasanComboKey: "newAtasanByKantorInduk",
      pegawaiComboKey: "newPegawaiByKantorInduk",
      dateChangedStart: false,
      dateChangedEnd: false,
      submitting: false,
      hariLibur: [],
    };
  }

  handleChangeInput = (e) => {
    const name = e.target.name;
    this.setState(
      {
        [name]: e.target.value,
      }
    );
  };

  _loading(status) {
    this.setState({
      isLoading: status
    });
  }

  handleChange = (e) => {
    let value = Array.from(e.target.selectedOptions, (option) => option.value);
    this.setState({ pegawai: value });
  };

  handleExp = (start, end) => {
    if (start === '' || end === '') {
      return false
    }
    
    const param = {
      start: start,
      end: end,
    };

    apiWs.post("/get-expired-date", param).then((res) => {
      // console.log(res.data.expiredDate);
      this.setState({ expired: res.data.expiredDate });
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const id = this.props.match.params.id;

    const {
      judul,
      jenis,
      start,
      end,
      tugas,
      pegawai,
      atasan,
      expired,
      status,
    } = this.state;

    if (!judul) {
      toastMessageCall("Judul tidak boleh kosong.", "top-center", "warning");
      return false;
    }
    if (jenis.length === 0) {
      toastMessageCall("Jenis tidak boleh kosong.", "top-center", "warning");
      return false;
    }
    if (!start) {
      toastMessageCall(
        "Tanggal mulai tidak boleh kosong.",
        "top-center",
        "warning"
      );
      return false;
    }
    if (!end) {
      toastMessageCall(
        "Tanggal akhir tidak boleh kosong.",
        "top-center",
        "warning"
      );
      return false;
    }
    if (!tugas) {
      toastMessageCall("Uraian tidak boleh kosong.", "top-center", "warning");
      return false;
    }

    const param = {
      judul,
      jenis,
      start,
      end,
      tugas,
      pegawai: pegawai.map((eachPegawai) => [
        eachPegawai[6],
        eachPegawai[2],
        eachPegawai[3],
        eachPegawai[1],
      ]),
      atasan: [
        atasan[6],
        atasan[2],
        atasan[3],
        atasan[1],
      ],
      status,
      exp: expired,
    };

    param.pegawai = param.pegawai.filter(
      (pegawai) => pegawai[0] !== this.state.session_credential.nama
    );
    // param.atasan = param.atasan.split(";");
    if (this.state.status === "Menunggu") {
      param.pegawai.unshift([
        this.state.session_credential.nama,
        this.state.session_credential.jabatan,
        this.state.session_credential.npp,
        this.state.session_credential.kantor,
      ]);
    }

    if (param.pegawai.length === 0) {
      toastMessageCall("Pegawai tidak boleh kosong.", "top-center", "warning");
      return false;
    }

    // console.log(param);
    // return false;

    this.setState({ submitting: true })
    apiWs
      .post(`/update-tugas/${id}`, param)
      .then((res) => {
        toastMessageCall("Berhasil mengubah tugas", "top-center", "success");
        const delayInMilliseconds = 1500;

        setTimeout(function () {
          window.history.go(-1);
        }, delayInMilliseconds);
      })
      .catch((err) => {
        toastMessageCall("Gagal mengubah tugas", "top-center", "danger");
      })
      .finally(() => {
        this.setState({ submitting: false })
      });
  };

  getData() {
    this._loading(true);

    let status;

    const id = this.props.match.params.id;

    apiWs.get("/jenis-tugas").then((res) => {
      if (res.data.result) {
        var ar = [];
        if (res.data.data.length) {
          res.data.data.map((item) => {
            ar.push(JSON.parse(item));
          });
        }
        this.setState({
          data_jenis: ar,
        });
      }
    });
    apiWs
      .get(`/tugas/${id}`)
      .then((res) => {
        var ar = [];
        if (res.data.data.length) {
          res.data.data.map((item) => {
            ar.push(JSON.parse(item));
          });
          this.setState(
            {
              judul: ar[0].judul,
              jenis: ar[0].jenis,
              tugas: ar[0].tugas,
              start: ar[0].start,
              end: ar[0].end,
              atasan: ar[0].nama_atasan,
              expired: ar[0].exp,
              status: ar[0].status,
            },
            () => {
              this.loadCombo(this.state.status);
            }
          );
        }

        var br = [];
        if (res.data.pegawai.length) {
          let selectedPegawai = [];
          res.data.pegawai.map((item) => {
            br.push(item);
          });
          br.filter((pegawai) => pegawai.npp !== this.state.session_credential.npp).map((pegawai) => {
            delete pegawai.nama_pegawai;
            delete pegawai.jabatan_pegawai;
            pegawai = {status_aktif: true, ...pegawai}
            selectedPegawai.push([
              pegawai.status_aktif,
              pegawai.kantor,
              pegawai.jabatan,
              pegawai.npp,
              '-', //foto
              0, //id
              pegawai.nama,
              pegawai.role
            ]);
          });
          selectedPegawai.sort(function (x, y) {
            return x[6] < y[6] ? -1 : 1;
          });
          this.setState({ pegawai: selectedPegawai });
        }

        if (res.data.atasan) {
          let selectedAtasan = res.data.atasan;
          console.log(selectedAtasan);
          delete selectedAtasan.nama_pegawai;
          delete selectedAtasan.jabatan_pegawai;
          selectedAtasan = {status_aktif: true, ...selectedAtasan}
          this.setState({ atasan: [
            selectedAtasan.status_aktif,
            selectedAtasan.kantor,
            selectedAtasan.jabatan,
            selectedAtasan.npp,
            '-', //foto
            0, //id
            selectedAtasan.nama,
            selectedAtasan.role
          ]});
        }
        this._loading(false);
      })
      .catch((err) => {
        toastMessageCall("Gagal terhubung dengan backend. Error : " + err);
      });
    
      apiWs
        .get("/hari-libur")
        .then(res => {
          // console.log(res);
          let hariLiburList = []
          res.data.data.map((v, i) => hariLiburList.push(v[0]))
          this.setState({hariLibur: hariLiburList})
        })
        .catch(error => {
          console.log(error);
        })
  }

  loadCombo(status) {
    // let pegawaiComboKey;
    // let atasanComboKey;

    // if (status == "Menunggu") {
    //   atasanComboKey = "atasanTerusExpanded";
    //   pegawaiComboKey = "satuKantorInduk";
    // } else {
    //   atasanComboKey = "setara";
    //   pegawaiComboKey = "bawahanTerus";
    // }

    const session_credential = {
      id_pegawai: this.state.session_credential.id_pegawai,
      npp: this.state.session_credential.npp,
      nama: this.state.session_credential.nama,
      jabatan: this.state.session_credential.jabatan,
      kantor: this.state.session_credential.kantor,
      role: this.state.session_credential.role,
    };

    // LOAD PEGAWAI
    var param = {
      key:
        this.state.pegawaiComboKey +
        "$" +
        session_credential.id_pegawai +
        "$" +
        session_credential.npp +
        "$" +
        session_credential.nama +
        "$" +
        session_credential.jabatan +
        "$" +
        session_credential.kantor +
        "$" +
        session_credential.role,
    };

    api
      .get("/pegawai/data/combo", { params: param })
      .then((res) => {
        if (!res.data.result) {
          toastMessageCall(res.data.message, "top-center", "warning");
          return false;
        }

        var ar = [];
        if (res.data.data.length) {
          res.data.data.map((item, i, row) => {
            const atasan = this.state.atasan;
            if (
              item[5] !== this.state.session_credential.id_pegawai &&
              item[3] !== atasan[3]
            ) {
              ar.push(item);
            }
          });
        }

        ar.sort(function (x, y) {
          return x[6] < y[6] ? -1 : 1;
        });
        this.setState({ data_pegawai: ar });
      })
      .catch((err) => {
        toastMessageCall("Gagal terhubung dengan backend. Error : " + err);
      });

    // LOAD ATASAN
    var param = {
      key:
        this.state.atasanComboKey +
        "$" +
        session_credential.id_pegawai +
        "$" +
        session_credential.npp +
        "$" +
        session_credential.nama +
        "$" +
        session_credential.jabatan +
        "$" +
        session_credential.kantor +
        "$" +
        session_credential.role,
    };

    api
      .get("/pegawai/data/combo", { params: param })
      .then((res) => {
        if (!res.data.result) {
          toastMessageCall(res.data.message, "top-center", "warning");
          return false;
        }

        var ar = [];
        if (res.data.data.length) {
          res.data.data.map((item, i, row) => {
            // if (item[5] !== this.state.session_credential.id_pegawai) {
            ar.push(item);
            // }
          });
        }

        ar.sort(function (x, y) {
          return x[6] < y[6] ? -1 : 1;
        });
        console.log(ar);
        this.setState({ data_atasan: ar });
      })
      .catch((err) => {
        toastMessageCall("Gagal terhubung dengan backend. Error : " + err);
      });
  }

  loadComboPegawai(status) {
    // let pegawaiComboKey;
    // let atasanComboKey;

    // if (status == "Menunggu") {
    //   atasanComboKey = "atasanTerusExpanded";
    //   pegawaiComboKey = "satuKantorInduk";
    // } else {
    //   atasanComboKey = "setara";
    //   pegawaiComboKey = "bawahanTerus";
    // }

    const session_credential = {
      id_pegawai: this.state.session_credential.id_pegawai,
      npp: this.state.session_credential.npp,
      nama: this.state.session_credential.nama,
      jabatan: this.state.session_credential.jabatan,
      kantor: this.state.session_credential.kantor,
      role: this.state.session_credential.role,
    };

    // LOAD PEGAWAI
    var param = {
      key:
        this.state.pegawaiComboKey +
        "$" +
        session_credential.id_pegawai +
        "$" +
        session_credential.npp +
        "$" +
        session_credential.nama +
        "$" +
        session_credential.jabatan +
        "$" +
        session_credential.kantor +
        "$" +
        session_credential.role,
    };

    api
      .get("/pegawai/data/combo", { params: param })
      .then((res) => {
        if (!res.data.result) {
          toastMessageCall(res.data.message, "top-center", "warning");
          return false;
        }

        var ar = [];
        if (res.data.data.length) {
          res.data.data.map((item, i, row) => {
            const atasan = this.state.atasan;
            // console.log(atasan);
            // console.log(item);
            if (
              item[5] !== this.state.session_credential.id_pegawai &&
              item[3] !== atasan[3]
            ) {
              ar.push(item);
            }
          });
        }

        ar.sort(function (x, y) {
          return x[6] < y[6] ? -1 : 1;
        });
        this.setState({ data_pegawai: ar });
      })
      .catch((err) => {
        toastMessageCall("Gagal terhubung dengan backend. Error : " + err);
      });
  }

  componentDidMount() {
    // const token = window.sessionStorage.getItem("token");
    const token = getCookie("token");
    const tokenBody = token.split(".")[1];
    const result = JSON.parse(atob(tokenBody));
    this.setState(
      {
        session_credential: result.sub,
      },
      () => {
        this.getData();
      }
    );
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.isLoading}
        bgColor='#f1f1f1'
        spinnerColor='#9ee5f8'
        textColor='#676767'
        logoSrc="../../assets/images/Logo-bankNTT1.png"
        text=''
      >
        <div className="animated fadeIn">
          <ToastContainer />
          <div className="container-fluid">
            <div className="block-header">
              <div className="row clearfix">
                <div className="col-md-6 col-sm-12">
                  <h2>Ubah Penugasan</h2>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Worksheet</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/">Job Desk</Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        Edit Penugasan
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-12">
              <div className="card">
                <div className="body">
                  <form >
                    {this.state.jenis === "Tugas Rutin" ? (
                      <div className="row">
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <label
                              className="input-group-text"
                              htmlFor="inputGroupSelect01"
                              style={{ width: "150px" }}
                            >
                              Jumlah
                            </label>
                          </div>
                          <input
                            type="number"
                            className="form-control"
                            name="nominal"
                            value={this.state.nominal}
                            onChange={(e) => this.handleChangeInput(e)}
                            placeholder="Input Angka Jumlah Transaksi/Pembukaan Rekening/Pendaftaran"
                            aria-label="Uraian"
                            aria-describedby="basic-addon1"
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="row">
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              style={{ width: "150px" }}
                            >
                              Judul Tugas
                            </span>
                          </div>
                          <input
                            type="text"
                            name="judul"
                            className="form-control"
                            value={this.state.judul}
                            onChange={(e) => this.handleChangeInput(e)}
                          />
                        </div>
                        {this.state.status !== "Diterima" && this.state.status !== "Diterima By System" && (
                          <div className="input-group mb-3">
                            {/* <div className="input-group-prepend">
                            <label
                              className="input-group-text"
                              htmlFor="inputGroupSelect01"
                              style={{ width: "150px" }}
                            >
                              Atasan
                            </label>
                          </div>
                          <select
                            value={this.state.atasan}
                            className="custom-select"
                            id="inputGroupSelect01"
                            name="atasan"
                            onChange={(e) => this.handleChangeInput(e)}
                          >
                            <option defaultValue>
                              --Silahkan Pilih Atasan--
                            </option>
                            {this.state.data_atasan.map((atasan, index) => {
                              return (
                                <option
                                  key={index}
                                  value={`${atasan[6]};${atasan[2]};${atasan[3]};${atasan[1]}`}
                                >{`${atasan[6]} (${atasan[2]} - ${atasan[1]})`}</option>
                              );
                            })}
                          </select> */}
                            {/* <ThemeProvider theme={darkTheme}> */}
                            <Autocomplete
                              sx={{ width: "100%" }}
                              options={this.state.data_atasan}
                              value={this.state.atasan}
                              isOptionEqualToValue={(option, value) =>
                                option[3] === value[3]
                              }
                              onChange={(event, newValue) =>
                                this.setState({ atasan: newValue }, () => {
                                  this.loadComboPegawai(this.state.status)
                                })
                              }
                              getOptionLabel={(option) => `${option[6]} (${option[2]} - ${option[1]})`}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Atasan"
                                  placeholder="Pilih Atasan"
                                />
                              )}
                            />
                            {/* </ThemeProvider> */}
                          </div>
                        )}
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <label
                              className="input-group-text"
                              htmlFor="inputGroupSelect01"
                              style={{ width: "150px" }}
                            >
                              Jenis Tugas
                            </label>
                          </div>
                          <select
                            className="custom-select"
                            id="inputGroupSelect01"
                            name="jenis"
                            value={this.state.jenis}
                            onChange={(e) => this.handleChangeInput(e)}
                          >
                            <option value="">
                              --Silahkan Pilih Jenis Tugas--
                            </option>
                            {this.state.data_jenis
                              .filter(
                                (e) =>
                                  e.jenis_tugas !== "Tugas Rutin" &&
                                  e.jenis_tugas !== "Tugas Event"
                              )
                              .map((d, i) => (
                                <option key={i} value={d.jenis_tugas}>
                                  {d.jenis_tugas === "Tugas Utama"
                                    ? "Tugas Utama/RBB"
                                    : d.jenis_tugas === "Tugas Tambahan"
                                      ? "Tugas Khusus/Tambahan"
                                      : "Bukan Tugas"}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <label
                              className="input-group-text"
                              htmlFor="inputGroupSelect01"
                              style={{ width: "150px" }}
                            >
                              Tgl Mulai
                            </label>
                          </div>
                          {!this.state.dateChangedStart && <>&nbsp;<span className="ml-3 my-auto">{moment(this.state.start).format("Do MMMM YYYY")}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                          <Flatpickr
                            options={{
                              disable: [...this.state.hariLibur,
                                function (date) {
                                  // return true to disable
                                  return date.getDay() === 0 || date.getDay() === 6;
                                },
                              ],
                              locale: {
                                firstDayOfWeek: 1, // start week on Monday
                              },
                              minDate: "today",
                              altInput: true,
                              altFormat: "j F Y",
                              dateFormat: "Y-m-d",
                              onChange: (selectedDates, dateStr, instance) => {
                                this.setState(
                                  {
                                    start: dateStr,
                                    dateChangedStart: true,
                                  },
                                  () => {
                                    this.handleExp(
                                      this.state.start,
                                      this.state.end
                                    );
                                  }
                                );
                              },
                            }}
                            placeholder="Klik untuk ubah tanggal"
                            value={this.state.start}
                          />
                        </div>
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <label
                              className="input-group-text"
                              htmlFor="inputGroupSelect01"
                              style={{ width: "150px" }}
                            >
                              Tgl Akhir
                            </label>
                          </div>
                          {!this.state.dateChangedEnd && <>&nbsp;<span className="ml-3 my-auto">{moment(this.state.end).format("Do MMMM YYYY")}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                          <Flatpickr
                            options={{
                              disable: [...this.state.hariLibur,
                                function (date) {
                                  // return true to disable
                                  return date.getDay() === 0 || date.getDay() === 6;
                                },
                              ],
                              locale: {
                                firstDayOfWeek: 1, // start week on Monday
                              },
                              minDate: "today",
                              altInput: true,
                              altFormat: "j F Y",
                              dateFormat: "Y-m-d",
                              onChange: (selectedDates, dateStr, instance) => {
                                this.setState(
                                  {
                                    end: dateStr,
                                    dateChangedEnd: true,
                                  },
                                  () => {
                                    this.handleExp(
                                      this.state.start,
                                      this.state.end
                                    );
                                  }
                                );
                              },
                            }}
                            className="fp-override"
                            placeholder="Klik untuk ubah tanggal"
                            value={this.state.end}
                          />
                        </div>
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              style={{ width: "150px" }}
                            >
                              Tgl Expired
                            </span>
                          </div>
                          <span className="ml-3 my-auto">
                            {this.state.expired !== ""
                              ? moment(this.state.expired).format("Do MMMM YYYY")
                              : ""}
                          </span>
                        </div>
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <label
                              className="input-group-text"
                              htmlFor="inputGroupSelect01"
                              style={{ width: "150px" }}
                            >
                              Uraian Tugas
                            </label>
                          </div>
                          <textarea
                          className="form-control"
                          name="tugas"
                          value={this.state.tugas}
                          onChange={(e) => this.handleChangeInput(e)}
                          rows={7}
                          placeholder="Uraian Tugas"
                          />
                        </div>
                        <div className="input-group mb-3">
                          {/* <div className="input-group-prepend">
                          <label
                            className="input-group-text"
                            htmlFor="inputGroupSelect01"
                            style={{ width: "150px" }}
                          >
                            Pegawai
                          </label>
                        </div>
                        <select
                          multiple={true}
                          value={this.state.pegawai}
                          className="custom-select"
                          id="inputGroupSelect02"
                          name="pegawai"
                          onChange={(e) => this.handleChange(e)}
                        >
                          <option defaultValue>
                            --Silahkan Pilih Pegawai--
                          </option>
                          {this.state.data_pegawai.map((pegawai, index) => {
                            return (
                              <option
                                key={index}
                                value={`${pegawai[6]};${pegawai[2]};${pegawai[3]};${pegawai[1]}`}
                              >{`${pegawai[6]} (${pegawai[2]} - ${pegawai[1]})`}</option>
                            );
                          })}
                        </select> */}

                          {/* <ThemeProvider theme={darkTheme}> */}
                          <Autocomplete
                            multiple
                            sx={{ width: "100%" }}
                            options={this.state.data_pegawai}
                            value={this.state.pegawai}
                            isOptionEqualToValue={(option, value) =>
                              option[3] === value[3]
                            }
                            onChange={(event, newValue) =>
                              this.setState({ pegawai: newValue })
                            }
                            getOptionLabel={(option) =>
                              `${option[6]} (${option[2]} - ${option[1]})`
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Pegawai"
                                placeholder="Pilih Pegawai"
                              />
                            )}
                          />
                          {/* </ThemeProvider> */}
                        </div>
                      </div>
                    )}
                    <div className="col-md-12 col-sm-12 text-right">
                      <Button
                        className="btn btn-sm btn-secondary"
                        onClick={() => {
                          window.history.go(-1);
                        }}
                      >
                        Batal
                      </Button>{" "}
                      <Button
                        className="btn btn-sm btn-primary"
                        color="primary"
                        type="button"
                        onClick={(e) => this.handleSubmit(e)}
                        disabled={this.state.submitting}
                      >
                        Simpan
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingScreen>
    );
  }
}
export default UpdateTugas;
