import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { apiWs } from "../../configs/axios";
import { toastMessageCall } from "../../helpers/Utils";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import swal from "sweetalert";
import LoadingScreen from 'react-loading-screen';

import {
  TabelWorksheet,
  JenisHandler,
  DateFormatter,
} from "./TabelWorksheet";
import { getCookie } from "../../helpers/CookieManager";

const Approval = () => {
  // const token = window.sessionStorage.getItem('token')
  const token = getCookie("token");
  const tokenBody = token.split(".")[1];
  const result = JSON.parse(atob(tokenBody));

  const [sessionCred, setSessionCred] = useState(result.sub);
  const [data, setData] = useState([]);
  const [isModal, setModal] = useState("");
  const [ket, setKet] = useState("");
  const [id, setId] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const [submiting, setSubmiting] = useState(false)
  const [tableHeader, setTableHeader] = useState([
    { field: "nama_pegawai", headerName: "Nama Pegawai" },
    { field: "jabatan_pegawai", headerName: "Jabatan Pegawai" },
    { field: "judul", headerName: "Tugas" },
    {
      field: "jenis",
      headerName: "Jenis Tugas",
      cellRendererFramework: (params) => (
        <JenisHandler jenis={params.data.jenis} />
      ),
    },
    {
      field: "start",
      headerName: "Tanggal Penugasan",
      type: 'dateColumn',
      valueFormatter: (params) => DateFormatter(params.data.start),
    },
    {
      field: "end",
      headerName: "Tanggal Akhir",
      type: 'dateColumn',
      valueFormatter: (params) => DateFormatter(params.data.end),
    },
    {
      field: "exp",
      headerName: "Tanggal Expired",
      type: 'dateColumn',
      valueFormatter: (params) => DateFormatter(params.data.exp),
    },
    {
      field: "id_tugas",
      headerName: "Aksi",
      cellRendererFramework: (params) => (
        <div>
          {sessionCred.role !== "admin" ? (
            <>
              <button
                className="btn btn-sm btn-danger"
                onClick={() => {
                  getId(params.data.id_tugas);
                  setModal("Modal Tolak");
                }}
                disabled={submiting}
              >
                Ditolak
              </button>{" "}
              <Link
                to={`/tugas/revisi/${params.data.id_tugas}`}
                className="btn btn-sm btn-info"
              >
                Review
              </Link>{" "}
              <button
                className="btn btn-sm btn-success"
                onClick={() => approve(params.data.id_tugas)}
                disabled={submiting}
              >
                Diterima
              </button>
            </>
          ) : (
            ""
          )}
        </div>
      ),
    },
  ]);

  const notif = (type, title, message) => {
    swal({
      title: title,
      text: message,
      icon: type,
    });
  };

  const _getData = (a) => {
    setIsLoading(true)
    if (a) {
      var param = { a, npp: sessionCred.npp }
      apiWs.get("/tugas/approval", {
        params: param
      }).then((res) => {
        var ar = [];
        if (res.data.data.length) {
          res.data.data.map((item) => {
            ar.push(JSON.parse(item));
          });
        }
        ar.sort(function (x, y) {
          return new Date(x.start) < new Date(y.start) ? 1 : -1;
        });
        // if (sessionCred.role === "admin")
        //   setData(ar.filter((d) => d.status === "Menunggu"));
        // else
        //   setData(
        //     ar.filter(
        //       (d) => d.status === "Menunggu" && d.nama_atasan === sessionCred.nama
        //     )
        //   );
        setData(ar)
        setIsLoading(false)
      });
    }
    else {
      var param = { npp: sessionCred.npp }
      apiWs.get("/tugas/approval", {
        params: param
      }).then((res) => {
        var ar = [];
        if (res.data.data.length) {
          res.data.data.map((item) => {
            ar.push(JSON.parse(item));
          });
        }
        ar.sort(function (x, y) {
          return new Date(x.start) < new Date(y.start) ? 1 : -1;
        });
        // if (sessionCred.role === "admin")
        //   setData(ar.filter((d) => d.status === "Menunggu"));
        // else
        //   setData(
        //     ar.filter(
        //       (d) => d.status === "Menunggu" && d.nama_atasan === sessionCred.nama
        //     )
        //   );
        setData(ar)
        setIsLoading(false)
      });
    }
  };

  const approve = (id) => {
    let swalOptions = {
      title: "Apakah anda yakin menerima tugas ini?",
      icon: "warning",
      closeOnClickOutside: false,
      dangerMode: false,
      buttons: {
        cancel: {
          text: "Batal",
          value: false,
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: "OK",
          value: true,
          visible: true,
          closeModal: false,
        },
      },
    };

    swal(swalOptions).then((action) => {
      if (action) {
        // console.log(sessionCred);
        // return false
        setSubmiting(true)
        apiWs
          .post(`/approve/${id}`, { approvedBy: sessionCred.nama })
          .then((res) => {
            swalOptions = {
              title: "Tugas Berhasil Diterima",
              icon: "success",
              closeOnClickOutside: false,
              buttons: "Tutup",
            };
            swal(swalOptions);

            _getData();
          })
          .catch((err) => {
            swalOptions = {
              title: "Tugas Gagal Diterima",
              icon: "error",
              closeOnClickOutside: false,
              buttons: "Tutup",
            };
            swal(swalOptions);
          })
          .finally(() => {
            setSubmiting(false)
          });
      }
    });
  };

  const getId = (id) => {
    setId(id);
  };

  const reject = (e) => {
    e.preventDefault();
    if (ket.length < 5) {
      toastMessageCall("Keterangan minimal 5 huruf", "top-center", "warning");
      return false;
    }

    let swalOptions = {
      title: "Apakah anda yakin menolak tugas ini?",
      icon: "warning",
      closeOnClickOutside: false,
      dangerMode: false,
      buttons: {
        cancel: {
          text: "Batal",
          value: false,
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: "OK",
          value: true,
          visible: true,
          closeModal: false,
        },
      },
    };

    swal(swalOptions).then((action) => {
      if (action) {
        setSubmiting(true)
        apiWs
          .post(`/reject/${id}`, {
            ket,
          })
          .then((res) => {
            swalOptions = {
              title: "Tugas Berhasil Ditolak",
              icon: "success",
              closeOnClickOutside: false,
              buttons: "Tutup",
            };
            swal(swalOptions);

            _getData();
            setModal("");
          })
          .catch((err) => {
            swalOptions = {
              title: "Tugas Gagal Ditolak",
              icon: "error",
              closeOnClickOutside: false,
              buttons: "Tutup",
            };
            swal(swalOptions);
          })
          .finally(() => {
            setSubmiting(false)
          });
      }
    });
  };

  useEffect(() => {
    _getData();
  }, []);
  return (
    <LoadingScreen
      loading={isLoading}
      bgColor='#f1f1f1'
      spinnerColor='#9ee5f8'
      textColor='#676767'
      logoSrc="../../assets/images/Logo-bankNTT1.png"
      text=''
    >
      <div className="container-fluid">
        <ToastContainer />
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h2>Tugas yang Diajukan Bawahan</h2>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Worksheet</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/">Approval Tugas</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Tugas yang Diajukan Bawahan
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <button className="btn btn-info"
          style={{
            position: "absolute", /* becomes a layer */
            right: '50px', /* relative to its parent wrapper */
            top: '98px', /* relative to its parent wrapper */
            zIndex: 2,
          }}
          onClick={() => _getData('test')}
        >Tampilkan seluruh tugas</button>
        <div className="row clearfix">
          <div className="col-12">
            <TabelWorksheet
              tableData={data}
              tableHeader={tableHeader}
              tableTitle={`Tabel Tugas yang Diajukan Bawahan`}
            />
          </div>
        </div>
        <div
          className={`modal fade  ${isModal === "Modal Tolak" ? "d-block show" : ""
            }`}
          id="exampleModal"
        >
          <div className="modal-dialog" role="document">
            <form onSubmit={(e) => reject(e)}>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Tolak Tugas
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setModal("")}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-group">
                    <textarea
                      onChange={(e) => setKet(e.target.value)}
                      rows={4}
                      className="form-control no-resize"
                      placeholder="Silahkan isi keterangan"
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" disabled={submiting} className="btn btn-round btn-danger">
                    Tolak
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </LoadingScreen>
  );
};

// const OldTableLayout = () => {
//     <div className="card">
//         <div className="body taskboard b-cyan planned_task">
//             <div className="table-responsive">
//                 <table className="table table-hover table-custom spacing8">
//                     <thead>
//                         <tr className='text-center'>
//                             <th>Nama</th>
//                             <th>Jabatan</th>
//                             {/* <th>Pegawai</th>
//                                         <th>Jabatan</th> */}
//                             <th>Tugas</th>
//                             <th>Jenis</th>
//                             <th>Tanggal Penugasan</th>
//                             <th>Tanggal Akhir</th>
//                             <th>Tanggal Expired</th>
//                             {/* <th>Prioritas</th> */}
//                             <th className="w200">Aksi</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {data.map((d, i) => (
//                             <tr key={i} className='text-center'>
//                                 <td>{d.nama_pegawai}</td>
//                                 <td>Jabatan</td>
//                                 {/* <td><h6 className="mb-0">{d.pegawai[0].nama}</h6></td>
//                                             <td><h6 className="mb-0">{d.pegawai[0].jabatan}</h6></td> */}
//                                 <td><span>{d.judul}</span></td>
//                                 <td>
//                                     {d.jenis === "Tugas Utama" && <span className="badge badge-success">Tugas Utama/RBB</span>}
//                                     {d.jenis === "Tugas Tambahan" && <span className="badge badge-primary">Tugas Khusus/Tambahan</span>}
//                                     {d.jenis === "Tugas Rutin" && <span className="badge badge-info">Tugas Rutin Harian</span>}
//                                 </td>
//                                 <td><span>{moment(d.start).format('D MMMM YYYY')}</span></td>
//                                 <td><span>{moment(d.start).format('D MMMM YYYY')}</span></td>
//                                 <td><span>{moment(d.start).format('D MMMM YYYY')}</span></td>
//                                 {/* <td>
//                                             {d.prioritas === "High" && <span className="text-danger">High</span>}
//                                             {d.prioritas === "Medium" && <span className="text-warning">Medium</span>}
//                                             {d.prioritas === "Low" && <span className="text-info">Low</span>}
//                                             {d.prioritas === "None" && <span className="text-seccondary">None</span>}
//                                         </td> */}
//                                 <td>
//                                     <button
//                                         className='btn btn-sm btn-danger'
//                                         onClick={() => {
//                                             getId(d.id)
//                                             setModal('Modal Tolak')
//                                         }}
//                                     >
//                                         Ditolak</button>{' '}
//                                     <Link to={`/tugas/revisi/${d.id}`} className='btn btn-sm btn-info'>Review</Link>{' '}
//                                     <button className='btn btn-sm btn-success' onClick={() => approve(d.id)}>Diterima</button>
//                                 </td>
//                             </tr>
//                         ))}
//                     </tbody>
//                 </table>
//             </div>
//         </div>
//     </div>
// }
export default Approval;
