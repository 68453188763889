import axios from "axios";
import React, { useEffect, useState, useMemo } from "react";
import { Link, NavLink } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { api } from "../../configs/axios";
import { tableBodyLevel } from "../Table/TableLevel";
import { tableBodyKantor } from "../Table/TableKantor";
import Tooltip from "../common/toolTip";
import { defaultLimit, toastMessageCall, encrypt } from "../../helpers/Utils";
import { comboLevel } from "../Combo/ComboLevel";
import { comboKantor } from "../Combo/ComboKantor";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import "react-toastify/dist/ReactToastify.css";
import { comboKantorInduk } from "../Combo/ComboKantorInduk";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.min.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import LoadingScreen from 'react-loading-screen';

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const Kantor = () => {
  const [dataComboKantorInduk, setDataComboKantorInduk] = useState([]);
  const [dataComboKantor, setDataComboKantor] = useState([]);

  const [dataTableLevel, setDataTableLevel] = useState([]);
  const [dataTableKantor, setDataTableKantor] = useState([]);
  const [dataKantorInduk, setDataKantorInduk] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModal, setModal] = useState("");

  const [keyLevel1, setkeyLevel1] = useState(""); //id_level
  const [xNamaLevel, setxNamaLevel] = useState("");

  const [keyKantor, setkeyKantor] = useState("");
  //const [keyKantor2, setkeyKantor2] = useState('');
  const [xKantorInduk, setxKantorInduk] = useState("");
  const [xLevel, setxLevel] = useState("");
  const [xKantor, setxKantor] = useState("");
  const [xKelas, setxKelas] = useState("");

  /** Filter */
  const [fKantorInduk, setfKantorInduk] = useState("-");
  const [fLevel, setfLevel] = useState("DIVISI");

  /** Paging */
  const [pnextPage, setpnextPage] = useState(0);
  const [pDisplay, setpDisplay] = useState("none");

  const [tableHeader, setTableHeader] = useState([
    { field: "kantor", headerName: "Kantor" },
    { field: "level", headerName: "Level" },
    { field: "kantor_induk", headerName: "Kantor Induk" },
    { field: "kelas", headerName: "Kelas" },
    {
      field: "id",
      headerName: "Aksi",
      cellRendererFramework: (params) => (
        <div>
          {/* <Tooltip text="Set Tidak Aktif" id="SetTidakAktif" />
                <button type="button" className="btn btn-sm btn-default" title="Set tidak Aktif" data-for="setTidakAktif" onClick={() => {
                    //setAktif(key);
                }}><i className="icon-close"></i></button>{" "} */}
          <Tooltip text="Edit" id="Edit" />
          <button
            type="button"
            className="btn btn-sm btn-default"
            title="Edit"
            data-for="edit"
            data-tip
            onClick={() => {
              loadDataKantor(params.data);
              setModal("modal-kantor");
            }}
          >
            <i className="icon-pencil"></i>
          </button>
          <Tooltip text="Hapus" id="Hapus" />
          <button
            type="button"
            className="btn btn-sm btn-default"
            title="Hapus"
            data-for="hapus"
            data-tip
            onClick={() => {
              loadDataKantor(params.data);
              setModal("modal-hapus-kantor");
            }}
          >
            <i className="icon-trash"></i>
          </button>
        </div>
      ),
    },
  ]);

  const defaultColDef = useMemo(() => ({
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth: 100,
  }));

  const loadDataComboKantorInduk = () => {
    api.get("/kantor").then((res) => {
      if (res.data.result) {
        var ar = [];
        if (res.data.data.length) {
          res.data.data.map((item) => {
            ar.push(JSON.parse(item));
          });
        }
        setDataComboKantorInduk(ar);
      }
    });
  };

  const loadDataComboKantor = () => {
    api.get("/kantor").then((res) => {
      if (res.data.result) {
        var ar = [];
        if (res.data.data.length) {
          res.data.data.map((item) => {
            ar.push(JSON.parse(item).kantor);
          });
        }
        ar.push("-");
        setDataComboKantor(ar);
      }
    });
  };

  const refreshDataTableLevel = () => {
    setLoading(true);
    api
      .get("/level")
      .then((res) => {
        if (!res.data.result) {
          toastMessageCall(res.data.message, "top-center", "warning");
          return false;
        }

        var ar = [];
        if (res.data.data.length) {
          res.data.data.map((item) => {
            ar.push(JSON.parse(item));
          });
        }
        setDataTableLevel(ar);
      })
      .catch((err) => {
        toastMessageCall("Gagal terhubungan dengan backend. Error : " + err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const refreshKantorInduk = () => {
    api
      .get("/kantor")
      .then((res) => {
        if (!res.data.result) {
          toastMessageCall(res.data.message, "top-center", "warning");
          return false;
        }
        var ar = [];
        if (res.data.data.length) {
          res.data.data.map((item) => {
            ar.push(JSON.parse(item));
          });
        }
        setDataKantorInduk(ar);
      })
      .catch((err) => {
        toastMessageCall("Gagal terhubungan dengan backend. Error :" + err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadDataLevel = (item) => {
    if (item) {
      setxNamaLevel(item.level);
      setkeyLevel1(item.id);
    }
  };

  const loadDataKantor = (item) => {
    if (item) {
      setxKantorInduk(item.kantor_induk);
      setxKantor(item.kantor);
      setxLevel(item.level);
      setxKelas(item.kelas);
      setkeyKantor(
        item.id +
        "~$~" +
        item.row_no +
        "~$~" +
        item.kantor_induk +
        "~$~" +
        item.level +
        "~$~" +
        item.kantor
      );
      //setkeyKantor2(item.row_no);
    }
  };

  const kosongBoxFilter = () => {
    setfKantorInduk("");
    setfLevel("DIVISI");
    window.location.reload(true);
  };

  const kosongBox = () => {
    setkeyLevel1("");
    setxNamaLevel("");

    setkeyKantor("");
    //setkeyKantor2('');
    setxKantorInduk("-");
    setxLevel("DIVISI");
    setxKantor("");
    setxKelas("");
  };

  /*** kantor */
  const refreshDataTableKantor = (page) => {
    setLoading(true);
    // var param = { limit: defaultLimit() }
    // if (fKantorInduk) {
    //     param = { key: fKantorInduk + '.' + fLevel, limit: defaultLimit(), start_row: page };
    // };
    api
      .get("/kantor")
      .then((res) => {
        if (!res.data.result) {
          toastMessageCall(res.data.message, "top-center", "warning");
          return false;
        }

        if (res.data.data.length < 1 && page == 0) {
          setpDisplay("none");
          setDataTableKantor([]);
          return false;
        }

        var ar = dataTableKantor;
        if (page == 0) {
          ar = [];
        }
        if (res.data.jumlah_data > ar.length) {
          if (res.data.data.length) {
            res.data.data.map((item, i, row) => {
              if (i + 1 == row.length) {
                let last_item = JSON.parse(item[0]);
                setpnextPage(last_item.row_no);
              }
              ar.push(JSON.parse(item));
            });
            setDataTableKantor(ar);
            setpDisplay("block");
          }
        } else {
          setpDisplay("none");
        }

        //setDataTableKantor(ar);
      })
      .catch((err) => {
        toastMessageCall("Gagal terhubung dengan backend. Error : " + err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const nextPage = () => {
    refreshDataTableKantor(pnextPage);
  };

  const simpanKantor = () => {
    api.get(`/cek-kantor/`, {
      params: {
        kantor: xKantor
      }
    })
      .then(res => {
        if (res.data.data.length >= 1) {
          toastMessageCall("Nama kantor sudah ada.", "top-center", "warning");
          return false;
        } else {
          if (!xLevel) {
            toastMessageCall("Level tidak boleh kosong.", "top-center", "warning");
            return false;
          }

          if (!xKantor) {
            toastMessageCall(
              "Nama kantor tidak boleh kosong",
              "top-center",
              "warning"
            );
            return false;
          }

          if (!xKelas) {
            toastMessageCall(
              "Kelas kantor tidak boleh kosong",
              "top-center",
              "warning"
            );
            return false;
          }

          setLoading(true);
          api
            .post("/kantor", {
              kantor_induk: xKantorInduk,
              level: xLevel,
              kantor: xKantor,
              key: keyKantor,
              kelas: xKelas,
            })
            .then((res) => {
              if (!res.data.result) {
                toastMessageCall(res.data.message, "top-center", "warning");
                return false;
              }
              toastMessageCall(res.data.message, "top-center", "success");
              refreshDataTableKantor(0);
              kosongBox();
              setModal("");
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              setLoading(false);
            });
        }
      })
      .catch(err => {
        console.log(err);
      })
  };

  const deleteKantor = () => {
    if (!keyKantor) {
      toastMessageCall("Belum ada data yang dipilih.");
      return false;
    }

    setLoading(true);
    api
      .delete("/kantor/" + keyKantor)
      .then((res) => {
        if (!res.data.result) {
          toastMessageCall(res.data.message, "top-center", "error");
          return false;
        }
        toastMessageCall(res.data.message, "top-center", "success");
        refreshDataTableKantor(0);
        setModal("");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /*** level */
  const simpanLevel = () => {
    if (!xNamaLevel) {
      toastMessageCall(
        "Nama level tidak boleh kosong.",
        "top-center",
        "warning"
      );
      return false;
    }
    setLoading(true);
    api
      .post("/level", {
        key1: keyLevel1,
        level: xNamaLevel.trim(),
      })
      .then((res) => {
        if (!res.data.result) {
          toastMessageCall(res.data.message, "top-center", "error");
          return false;
        }

        toastMessageCall(res.data.message, "top-center", "success");
        refreshDataTableLevel();
        kosongBox();
        setModal("");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteLevel = () => {
    if (!keyLevel1) {
      toastMessageCall("Belum ada data yang dipilih", "top-center", "warning");
      setModal("");
      return false;
    }

    setLoading(true);
    api
      .delete("/level/" + keyLevel1)
      .then((res) => {
        if (!res.data.result) {
          toastMessageCall(res.data.message, "top-center", "error");
          return false;
        }
        toastMessageCall(res.data.message, "top-center", "success");
        refreshDataTableLevel();
        setModal("");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadDataComboKantor();
    loadDataComboKantorInduk();
    refreshDataTableLevel();
    //refreshKantorInduk();
    refreshDataTableKantor(0);
  }, []);

  function ExcelExportKantor({ data }) {
    return (
      <ExcelFile
        filename={`Data Kantor`}
        element={
          <button
            type="button"
            className="btn btn-sm btn-success btn-block mr-1"
          >
            Export Data Kantor ke Excel
          </button>
        }
      >
        <ExcelSheet data={data} name="Kantor">
          <ExcelColumn label="Kantor" value="kantor" />
          <ExcelColumn label="Level" value="level" />
          <ExcelColumn label="Kantor Induk" value="kantor_induk" />
          <ExcelColumn label="Kelas" value="kelas" />
        </ExcelSheet>
      </ExcelFile>
    );
  }

  function ExcelExportLevel({ data }) {
    return (
      <ExcelFile
        filename={`Data Level`}
        element={
          <button
            type="button"
            className="btn btn-sm btn-success btn-block mr-1"
          >
            Export Data Level ke Excel
          </button>
        }
      >
        <ExcelSheet data={data} name="Level">
          <ExcelColumn label="Level" value="level" />
        </ExcelSheet>
      </ExcelFile>
    );
  }

  return (
    <LoadingScreen
      loading={loading}
      bgColor='#f1f1f1'
      spinnerColor='#9ee5f8'
      textColor='#676767'
      logoSrc="../../assets/images/Logo-bankNTT1.png"
      text=''
    >
      <ToastContainer />
      <div className="container-fluid">
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-12 col-sm-12">
              <h2>Kantor</h2>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Worksheet</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Kantor
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div className="row clearfix">
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div className="row mb-3">
              <div className="col-md-2 col-sm-12">
                <button
                  type="button"
                  className="btn btn-sm btn-primary mb-2 w-100"
                  onClick={() => {
                    kosongBox();
                    setModal("modal-kantor");
                  }}
                >
                  Tambah Kantor
                </button>
              </div>
              <div className="col-md-3 col-sm-12">
                <ExcelExportKantor data={dataTableKantor} />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <div
                  className="ag-theme-alpine"
                  style={{ width: "100%", height: 700 }}
                >
                  <AgGridReact
                    rowData={dataTableKantor} // Row Data for Rows
                    columnDefs={tableHeader} // Column Defs for Columns
                    animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                    defaultColDef={defaultColDef}
                    pagination={true}
                    paginationPageSize={20}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="row">
              <div className="col">
                <button
                  type="button"
                  className="btn btn-sm btn-primary mb-2 w-100"
                  onClick={() => {
                    kosongBox();
                    setModal("modal-level");
                  }}
                >
                  Tambah Level
                </button>
              </div>
              <div className="col">
                <ExcelExportLevel data={dataTableLevel} />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="table-responsive">
                  <table className="table table-hover table-custom spacing8">
                    <thead>
                      <tr>
                        <th className="w30">NO</th>
                        <th>LEVEL</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableBodyLevel(
                        loading,
                        dataTableLevel,
                        { loadDataLevel },
                        { setModal }
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/** modal level **/}
      <div
        className={`modal fade ${isModal === "modal-level" ? "d-block show" : ""
          }`}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Level</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setModal("")}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" style={{ width: "150px" }}>
                    Level
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  aria-label="Level"
                  aria-describedby="basic-addon1"
                  value={xNamaLevel}
                  onChange={(e) => {
                    setxNamaLevel(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-round btn-default"
                data-dismiss="modal"
                onClick={() => {
                  setModal("");
                  kosongBox();
                }}
              >
                Tutup
              </button>
              <button
                type="button"
                className="btn btn-round btn-primary"
                onClick={() => {
                  simpanLevel();
                }}
              >
                Simpan
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal fade ${isModal === "modal-hapus-level" ? "d-block show" : ""
          }`}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Level</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setModal("")}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body text-center pt-4 mb-4">
              <h5>Yakin data akan dihapus?</h5>
              <p className="mb-4">
                Data yang sudah dihapus tidak bisa dikembalikan lagi.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-round btn-default"
                data-dismiss="modal"
                onClick={() => {
                  setModal("");
                  kosongBox();
                }}
              >
                Tutup
              </button>
              <button
                type="button"
                className="btn btn-round btn-primary"
                onClick={() => {
                  deleteLevel();
                }}
              >
                Hapus
              </button>
            </div>
          </div>
        </div>
      </div>

      {/** modal kantor **/}
      <div
        className={`modal fade ${isModal === "modal-kantor" ? "d-block show" : ""
          }`}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Kantor</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setModal("")}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group mb-3">
                {/* <ThemeProvider theme={darkTheme}> */}
                <Autocomplete
                  id="combo-box-demo"
                  size="small"
                  options={dataComboKantor}
                  autoHighlight
                  value={xKantorInduk || null}
                  onChange={(e, v) => {
                    setxKantorInduk(v);
                  }}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Kantor Induk"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                {/* </ThemeProvider> */}
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" style={{ width: "150px" }}>
                    Level
                  </span>
                </div>
                <select
                  className="custom-select form-control"
                  id="inputGroupSelect01"
                  value={xLevel}
                  onChange={(e) => {
                    setxLevel(e.target.value);
                  }}
                >
                  {comboLevel(dataTableLevel)}
                </select>
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" style={{ width: "150px" }}>
                    Nama Kantor
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  aria-label="Level"
                  aria-describedby="basic-addon1"
                  value={xKantor}
                  onChange={(e) => {
                    setxKantor(e.target.value);
                  }}
                />
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" style={{ width: "150px" }}>
                    Kelas
                  </span>
                </div>
                <select
                  className="custom-select form-control"
                  id="inputGroupSelect01"
                  value={xKelas}
                  onChange={(e) => {
                    setxKelas(e.target.value);
                  }}
                >
                  <option value="">-- Silakan Pilih Kelas Kantor --</option>
                  <option value="A">Kelas A</option>
                  <option value="B">Kelas B</option>
                  <option value="C">Kelas C</option>
                </select>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-round btn-default"
                data-dismiss="modal"
                onClick={() => setModal("")}
              >
                Tutup
              </button>
              <button
                type="button"
                className="btn btn-round btn-primary"
                onClick={() => {
                  simpanKantor();
                }}
              >
                Simpan
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal fade ${isModal === "modal-hapus-kantor" ? "d-block show" : ""
          }`}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Kantor</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setModal("")}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body text-center pt-4 mb-4">
              <h5>Yakin data akan dihapus?</h5>
              <p className="mb-4">
                Data yang sudah dihapus tidak bisa dikembalikan lagi.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-round btn-default"
                data-dismiss="modal"
                onClick={() => {
                  setModal("");
                  kosongBox();
                }}
              >
                Tutup
              </button>
              <button
                type="button"
                className="btn btn-round btn-primary"
                onClick={() => {
                  deleteKantor();
                }}
              >
                Hapus
              </button>
            </div>
          </div>
        </div>
      </div>
    </LoadingScreen>
  );
};

export default Kantor;
