import React, { useState, useEffect } from 'react';
import Nestable from 'react-nestable';
import { api, apiWs } from '../../configs/axios';
import { Link } from 'react-router-dom';
import { TabelWorksheet, JenisHandler, StatusHandler, FormatDate } from './TabelWorksheet';

const Taskboard = () => {

  const [data, setData] = useState([])

  const _getData = () => {
    apiWs.get('/tugas')
      .then(res => {
        var ar = [];
        if (res.data.data.length) {
          res.data.data.map((item) => {
            console.log(JSON.parse(item))
            ar.push(JSON.parse(item))
          });
        };  
        setData(ar)
      })
  }

  useEffect(() => {
    _getData()
  }, []);

  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row clearfix">
          <div className="col-md-12 col-sm-12">
            <h2>Histori Tugas</h2>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="/">KPI</a></li>
                <li className="breadcrumb-item"><a href="/">Tugas</a></li>
                <li className="breadcrumb-item active" aria-current="page">Histori Tugas</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>      
      <div className="row clearfix">
        <div className="col-12">
            <div className="card">
                <div className="body b-cyan">
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Nama Pegawai" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Jabatan" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Prioritas" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <a href="#" className="btn btn-sm btn-primary btn-block" title="">Cari</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div className="row clearfix">
      <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card">
            <div className="body taskboard b-cyan planned_task">
              <h6 className="font300 mb-3">Tabel Histori Tugas</h6>
              <div className="table-responsive">
              <table className="table table-hover table-custom spacing8">
                <thead>
                  <tr>
                    <th>Foto</th>
                    <th>Tugas</th>
                    <th>Jenis</th>
                    <th>Tanggal Penugasan</th>
                    <th>Tanggal Akhir</th>
                    <th>Status</th>
                    <th>Aksi</th>
                  </tr>
                </thead>
                <tbody>
                {data.filter((d) => d.status === "Ditolak" || d.status === "Selesai").map((e, i) => (
                  <tr key={i}>
                    <td>
                      <ul className="list-unstyled sm team-info margin-0">
                        <li><img src="../assets/images/xs/user.png" alt="Avatar" /></li>
                        <li><img src="../assets/images/xs/user.png" alt="Avatar" /></li>
                      </ul>
                    </td>
                    <td><span>{e.judul}</span></td>
                    <td>
                        {e.jenis === "Tugas Utama" && <span className="badge badge-success">Tugas Utama</span>}
                        {e.jenis === "Tugas Tambahan" && <span className="badge badge-primary">Tugas Tambahan</span>}
                    </td>
                    <td><span>{e.start}</span></td>
                    <td><span>{e.end}</span></td>
                    <td>
                      <StatusHandler status={e.status}/>
                    </td>
                    <td>
                        <Link className='btn btn-sm btn-info' to={`/detail-project-list/${e.id}`}>Detail</Link>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
              </div>
            </div>
          </div>
      </div>
      </div>
    </div>
  )
}

export default Taskboard;