import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Tooltip from "../common/toolTip";
import { apiWs } from "../../configs/axios";
import { toastMessageCall } from "../../helpers/Utils";

import "react-toastify/dist/ReactToastify.css";

import { TabelWorksheet, FormatDate } from "../Worksheet/TabelWorksheet";

import "flatpickr/dist/themes/airbnb.css";
import Flatpickr from "react-flatpickr";
import ReactExport from "react-export-excel";
import LoadingScreen from 'react-loading-screen';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function JudulTugasRutin() {
  const [data, setData] = useState([]);
  const [isModal, setModal] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [id, setId] = useState(0)
  const [judulTugasRutin, setJudulTugasRutin] = useState("");

  const [tableHeader, setTableHeader] = useState([
    { field: [1], headerName: "Judul Tugas" },
    {
      field: [0],
      headerName: "Aksi",
      cellRendererFramework: (params) => (
        <div>
          <Tooltip text="Edit" id="Edit" />
          <button
            type="button"
            className="btn btn-sm btn-default"
            title="Edit"
            data-for="edit"
            data-tip
            onClick={() => {
              loadData(params.data);
              setIsUpdate(true);
              setModal("modal-judul-tugas-rutin");
            }}
          >
            <i className="icon-pencil"></i>
          </button>{" "}
          <Tooltip text="Hapus" id="Hapus" />
          <button
            type="button"
            className="btn btn-sm btn-default"
            title="Hapus"
            data-for="hapus"
            data-tip
            onClick={() => {
              loadData(params.data);
              setModal("modal-hapus");
            }}
          >
            <i className="icon-trash"></i>
          </button>
        </div>
      ),
    },
  ]);

  function loadData(item) {
    setId(item[0]);
    setJudulTugasRutin(item[1]);
  }

  function emptyData() {
    setId(0);
    setJudulTugasRutin("");
    setIsUpdate(false);
  }

  function getData() {
    setIsLoading(true)
    emptyData();
    apiWs
      .get(`/judul-tugas-rutin`)
      .then((res) => {
        setData(res.data.data);
        setIsLoading(false)
      })
      .catch((error) => toastMessageCall(error, "top-center", "warning"));
  }

  function postData(e) {
    e.preventDefault();
    if (validateForm()) {
      if (isUpdate) {
        apiWs
          .patch(`/judul-tugas-rutin/${id}`, { judulTugasRutin })
          .then((res) => {
            toastMessageCall(res.data.message, "top-center", "success");
          })
          .catch((error) => toastMessageCall(error, "top-center", "warning"))
          .finally(() => {
            setModal("");
            emptyData();
            getData();
          });
      } else {
        apiWs
          .post(`/judul-tugas-rutin`, { judulTugasRutin })
          .then((res) => {
            toastMessageCall(res.data.message, "top-center", "success");
          })
          .catch((error) => toastMessageCall(error, "top-center", "warning"))
          .finally(() => {
            setModal("");
            emptyData();
            getData();
          });
      }
    }
  }

  function validateForm() {
    if (!judulTugasRutin) {
      toastMessageCall(
        "Pastikan judul tugas rutin tidak kosong!",
        "top-center",
        "warning"
      );
      return false;
    }
    if (judulTugasRutin.length < 5) {
      toastMessageCall(
        "Pastikan judul tugas rutin tidak kurang dari 5 karakter!",
        "top-center",
        "warning"
      );
      return false;
    }
    return true;
  }

  function deleteData() {
    apiWs
      .delete(`/judul-tugas-rutin/${id}`)
      .then((res) => {
        toastMessageCall(res.data.message, "top-center", "success");
      })
      .catch((error) => toastMessageCall(error, "top-center", "warning"))
      .finally(() => {
        setModal("");
        emptyData();
        getData();
      });
  }

  useEffect(() => {
    getData();
  }, []);

  function ExcelExport({ data }) {
    return (
      <ExcelFile filename={`Data Judul Tugas Rutin`} element={
        <button type="button" className="btn btn-sm btn-success btn-block mr-1">
          Export Data Judul Tugas Rutin ke Excel
        </button>}>
        <ExcelSheet data={data} name="Judul Tugas Rutin">
          <ExcelColumn label="Judul Tugas Rutin" value={[1]} />
        </ExcelSheet>
      </ExcelFile>
    );
  }

  // useEffect(() => {
  //   console.log(data);
  // }, [data])

  return (
    <LoadingScreen
      loading={isLoading}
      bgColor='#f1f1f1'
      spinnerColor='#9ee5f8'
      textColor='#676767'
      logoSrc="../../assets/images/Logo-bankNTT1.png"
      text=''
    >
      <div className="animated-fadeIn">
        <ToastContainer />
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-md-7 col-sm-12">
                <h2>Judul Tugas Rutin</h2>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Worksheet</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Judul Tugas Rutin
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="col-md-2 col-sm-12 text-right">
                <button
                  type="button"
                  className="btn btn-sm btn-primary btn-block mr-1"
                  onClick={() => {
                    //kosongBox();
                    setModal("modal-judul-tugas-rutin");
                  }}
                >
                  Tambah Judul Tugas Rutin
                </button>
              </div>
              <div className="col-md-3 col-sm-12 text-right">
                <ExcelExport data={data} />
              </div>
            </div>
          </div>
          <TabelWorksheet
            tableData={data}
            tableHeader={tableHeader}
            tableTitle={`Tabel Judul Tugas Rutin`}
          />
        </div>

        <div
          className={`modal fade ${isModal === "modal-judul-tugas-rutin" ? "d-block show" : ""
            }`}
        >
          <form onSubmit={(e) => postData(e)}>
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Judul Tugas Rutin</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setModal("");
                      emptyData();
                    }}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text"
                        style={{ width: "150px" }}
                      >
                        Judul Tugas
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      aria-label="judulTugasRutin"
                      aria-describedby="basic-addon1"
                      value={judulTugasRutin}
                      name={`judulTugasRutin`}
                      onChange={(e) => setJudulTugasRutin(e.target.value)}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-round btn-default"
                    data-dismiss="modal"
                    onClick={() => {
                      setModal("");
                      emptyData();
                    }}
                  >
                    Tutup
                  </button>
                  <button type="submit" className="btn btn-round btn-primary">
                    Simpan
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div
          className={`modal fade ${isModal === "modal-hapus" ? "d-block show" : ""
            }`}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Judul Tugas Rutin</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setModal("");
                    emptyData();
                  }}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body text-center pt-4 mb-4">
                <h5>Yakin data akan dihapus?</h5>
                <p className="mb-4">
                  Data yang sudah dihapus tidak bisa dikembalikan lagi.
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-round btn-default"
                  data-dismiss="modal"
                  onClick={() => {
                    setModal("");
                    emptyData();
                  }}
                >
                  Tutup
                </button>
                <button
                  type="button"
                  className="btn btn-round btn-primary"
                  onClick={() => deleteData()}
                >
                  Hapus
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoadingScreen>
  );
}
