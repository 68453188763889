import React, { useEffect, useState, useMemo } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Tooltip from '../common/toolTip';
import { api, apiWs } from '../../configs/axios';
import {
    defaultLimit,
    toastMessageCall,
} from '../../helpers/Utils';

import 'react-toastify/dist/ReactToastify.css';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.min.css';
import ReactExport from "react-export-excel";
import LoadingScreen from 'react-loading-screen';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Skill = () => {
    const [data, setData] = useState([])
    const [skill, setSKill] = useState('')
    const [xId, setXid] = useState(0)
    const [isModal, setModal] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [tableHeader, setTableHeader] = useState([
        { field: [1], headerName: 'Skill' },
        {
            field: 'id', headerName: 'Aksi', cellRendererFramework: (params) => <div>
                <Tooltip text="Edit" id="Edit" />
                <button type="button" className="btn btn-sm btn-default" title="Edit" data-for="edit" data-tip onClick={() => {
                    loadData(params.data);
                    setModal('modal-tambah');
                }}><i className="icon-pencil"></i></button>{" "}
                <Tooltip text="Hapus" id="Hapus" />
                <button type="button" className="btn btn-sm btn-default" title="Hapus" data-for="hapus" data-tip onClick={() => {
                    loadData(params.data);
                    setModal('modal-hapus');
                }}><i className="icon-trash"></i></button>
            </div>
        }
    ])

    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1,
        minWidth: 100,
    }));

    const loadData = (item) => {
        setSKill(item[1])
        setXid(item[0])
    }

    const getData = () => {
        setIsLoading(true)
        apiWs.get(`/skill`)
            .then(res => {
                var ar = [];
                if (res.data.data.length) {
                    res.data.data.map((item, i, row) => {
                        ar.push(item);
                    });
                    setData(ar)
                    setIsLoading(false)
                };
            })
            .catch(err => {

            })
    }

    const postData = e => {
        e.preventDefault()

        if (!skill) {
            toastMessageCall('Skill tidak boleh kosong', 'top-center', 'warning');
            return false
        }

        var param = {
            skill
        }

        if (xId) {
            apiWs.post(`/skill/${xId}`, param)
                .then(res => {
                    toastMessageCall('Skill berhasil diubah', 'top-center', 'success');
                    getData()
                    setModal('')
                    setSKill('')
                    setXid(0)
                })
                .catch(err => {
                    toastMessageCall('Skill gagal diubah', 'top-center', 'error');
                })
        } else {
            apiWs.post(`/skill`, param)
                .then(res => {
                    toastMessageCall('Berhasil membuat skill', 'top-center', 'success');
                    getData()
                    setModal('')
                    setSKill('')
                })
                .catch(err => {
                    toastMessageCall('Gagal membuat skill', 'top-center', 'error');
                })
        }
    }

    const deleteData = (id) => {
        apiWs.delete(`/skill/${id}`)
            .then(res => {
                toastMessageCall('Skill berhasil dihapus', 'top-center', 'success');
                getData()
                setModal('')
                setSKill('')
                setXid(0)
            })
            .catch(err => {
                toastMessageCall('Skill gagal dihapus', 'top-center', 'error');
            })
    }

    useEffect(() => {
        getData()
    }, []);

    function ExcelExport({ data }) {
        return (
            <ExcelFile filename={`Data Skill`} element={
                <button type="button" className="btn btn-sm btn-success btn-block mr-1">
                    Export Data Skill ke Excel
                </button>}>
                <ExcelSheet data={data} name="Skill">
                    <ExcelColumn label="Skill" value={[1]} />
                </ExcelSheet>
            </ExcelFile>
        );
    }

    return (
        <LoadingScreen
            loading={isLoading}
            bgColor='#f1f1f1'
            spinnerColor='#9ee5f8'
            textColor='#676767'
            logoSrc="../../assets/images/Logo-bankNTT1.png"
            text=''
        >
            <div className='animated-fadeIn'>
                <ToastContainer />
                <div className="container-fluid">
                    <div className="block-header">
                        <div className="row clearfix">
                            <div className="col-md-8 col-sm-12">
                                <h2>Skill</h2>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Worksheet</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Skill</li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="col-md-2 col-sm-12 text-right">
                                <button type='button' className='btn btn-sm btn-primary btn-block mr-1' onClick={() => {
                                    setModal('modal-tambah');
                                }}>Tambah Skill</button>
                            </div>
                            <div className="col-md-2 col-sm-12 text-right">
                                <ExcelExport data={data} />
                            </div>
                        </div>
                    </div>
                    <div className="row clearfix">
                        <div className="ag-theme-alpine" style={{ width: '100%', height: 700 }}>
                            <AgGridReact
                                rowData={data} // Row Data for Rows
                                columnDefs={tableHeader} // Column Defs for Columns
                                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                                defaultColDef={defaultColDef}
                                pagination={true}
                                paginationPageSize={20}
                            />
                        </div>
                    </div>
                </div>

                <div className={`modal fade ${isModal === 'modal-tambah' ? 'd-block show' : ''}`}>
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h5 className='modal-title'>Skill</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setModal('')}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className='modal-body'>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" style={{ width: '150px' }}>Skill</span>
                                    </div>
                                    <input type="text" className="form-control" placeholder="" aria-label="nama" aria-describedby="basic-addon1" value={skill} onChange={(e) => { setSKill(e.target.value) }} />
                                </div>
                            </div>
                            <div className='modal-footer'>
                                <button type="button" className="btn btn-round btn-default" data-dismiss="modal" onClick={() => {
                                    setModal('');
                                    setSKill('');
                                    setXid(0);
                                }}>Tutup</button>
                                <button type="button" className="btn btn-round btn-primary" onClick={e => {
                                    postData(e);
                                }} >Simpan</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`modal fade ${isModal === 'modal-hapus' ? 'd-block show' : ''}`} >
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h5 className='modal-title'>Skill</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setModal('')}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className='modal-body text-center pt-4 mb-4'>
                                <h5>Yakin data akan dihapus?</h5>
                                <p className="mb-4">Data yang sudah dihapus tidak bisa dikembalikan lagi.</p>
                            </div>
                            <div className='modal-footer'>
                                <button type="button" className="btn btn-round btn-default" data-dismiss="modal" onClick={() => {
                                    setModal('');
                                    setXid(0);
                                }}>Tutup</button>
                                <button type="button" className="btn btn-round btn-primary" onClick={() => {
                                    deleteData(xId);
                                }}>Hapus</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LoadingScreen>
    )

}
export default Skill