import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { api } from "../../configs/axios";
import { toastMessageCall } from "../../helpers/Utils";
import { comboKantor } from "../Combo/ComboKantor";
import { comboJabatan } from "../Combo/ComboJabatan";
import { tableBodyStruktur } from "../Table/tableStruktur";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider, createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

const Struktur = () => {
  const [dataComboDireksi, setDataComboDireksi] = useState([]);
  const [dataComboDivisi, setDataComboDivisi] = useState([]);
  const [dataComboJabatan, setDataComboJabatan] = useState([]);

  const [dataTable, setDataTable] = useState([]);
  const [dataTableParent, setDataTableParent] = useState([]);
  const [dataTableChild, setDataTableChild] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModal, setModal] = useState("");

  const [key, setKey] = useState("");
  const [xId, setxId] = useState("");
  const [xDireksi, setxDireksi] = useState("");
  const [xParent, setxParent] = useState("-");
  const [xChild, setxChild] = useState("");

  const [fId, setfId] = useState("");
  const [fParent, setfParent] = useState("");
  const [fChild, setfChild] = useState("");

  const kosongBox = () => {
    setKey("");
    setxDireksi("");
    setxParent("-");
    setxChild("");

    setfId("");
    setfParent("");
    setfChild("");
  };

  const loadDataDireksi = () => {
    var param = { key: "~$~1" };
    api.get("/jabatan", { params: param }).then((res) => {
      if (res.data.result) {
        var ar = [];
        if (res.data.data.length) {
          res.data.data.map((item) => {
            ar.push(JSON.parse(item));
          });
        }
        setDataComboDireksi(ar);
      }
    });
  };

  const loadDataDivisi = () => {
    api.get("/kantor").then((res) => {
      if (res.data.result) {
        var ar = [];
        if (res.data.data.length) {
          res.data.data.map((item) => {
            ar.push(JSON.parse(item));
          });
        }
        setDataComboDivisi(ar);
      }
    });
  };

  const loadDataJabatan = () => {
    var param = { key: "~$~2" };
    api.get("/jabatan", { params: param }).then((res) => {
      if (res.data.result) {
        var ar = [];
        if (res.data.data.length) {
          res.data.data.map((item) => {
            ar.push(JSON.parse(item));
          });
        }
        setDataComboJabatan(ar);
      }
    });
  };

  const loadDataTable = () => {
    setLoading(true);
    var param = { key: fParent + "~$~" + fChild + "~$~" + fId };
    api
      .get("/struktur", { params: param })
      .then((res) => {
        if (!res.data.result) {
          toastMessageCall(res.data.message, "top-center", "warning");
          return false;
        }

        var ar = [];
        let parent = [];
        let child = [];
        if (res.data.data.length) {
          // parent = res.data.data.map(item=>item.parent_jabatan);
          res.data.data.map((item, i, row) => {
            var rec = JSON.parse(item);
            if (rec.parent_jabatan != "-") {
              parent.push(rec.parent_jabatan);
              child.push(rec);
            } else {
              parent.push(rec.child_jabatan);
            }
          });
          parent = [...new Set(parent)]; //removes dupes items
          // console.log(parent);
          // console.log(child);
          setDataTableParent(parent);
          setDataTableChild(child);
          //setDataTable(ar);
        }
      })
      .catch((err) => {
        toastMessageCall("Gagal terhubungan dengan backend. Error : " + err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadData = (item) => {
    setKey(
      item.parent_jabatan + "~$~" + item.child_jabatan + "~$~" + item.id_struktur
    );
    setxParent(item);
  };

  const simpanParent = () => {
    if (!xDireksi) {
      toastMessageCall("Direksi tidak boleh kosong.", "warning");
      return false;
    }

    var param = { parent: "-", child: xDireksi };
    setLoading(true);
    api
      .post("/struktur", {
        parent: "-",
        child: xDireksi,
      })
      .then((res) => {
        if (!res.data.result) {
          toastMessageCall(res.data.message, "top-center", "warning");
          return false;
        }

        toastMessageCall(res.data.message, "top-center", "success");
        kosongBox();
        setModal("");
        loadDataTable();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const simpanChild = () => {
    if (!xParent) {
      toastMessageCall("Belum ada data yang dipilih.", "top-center", "warning");
      return false;
    }

    if (!xChild) {
      toastMessageCall("Bawahan tidak boleh kosong", "top-center", "warning");
      return false;
    }

    setLoading(true);
    api
      .post("/struktur", {
        parent: xParent,
        child: xChild,
      })
      .then((res) => {
        if (!res.data.result) {
          toastMessageCall(res.data.message, "top-center", "warning");
          return false;
        }

        toastMessageCall(res.data.message, "top-center", "success");
        kosongBox();
        setModal("");
        loadDataTable();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteStruktur = () => {
    if (!key) {
      toastMessageCall("Belum ada data yang dipilih.", "top-center", "warning");
      return false;
    }

    setLoading(true);
    api
      .delete("/struktur/" + key)
      .then((res) => {
        if (!res.data.result) {
          toastMessageCall(res.data.message, "top-center", "error");
          return false;
        }

        toastMessageCall(res.data.message, "top-center", "success");
        loadDataTable();
        kosongBox("");
        setModal("");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadDataDireksi();
    loadDataDivisi();
    loadDataJabatan();
    loadDataTable();
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="container-fluid">
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h2>Struktur Organisasi</h2>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Worksheet</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Struktur Organisasi
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-md-6 col-sm-12 text-right hidden-xs">
              <button
                type="button"
                className="btn btn-sm btn-primary mr-1 mb-2"
                onClick={() => {
                  kosongBox();
                  setModal("modal-parent");
                }}
              >
                Tambah Parent
              </button>
            </div>
          </div>
        </div>
        <div className="row clearfix">
          {/* Percobaan tree 
                    <ul>
                        <li>1
                            <ul>
                                <li>1.1</li>
                                <li>1.2</li>
                                <li>1.3</li>
                                <li>1.4</li>
                                <li>1.5
                                    <ul>
                                        <li>1.5.1</li>
                                        <li>1.5.2</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>2</li>
                        <li>3</li>
                        <li>4</li>
                    </ul> */}
          {tableBodyStruktur(
            loading,
            dataTableParent,
            dataTableChild,
            { loadData },
            { setModal }
          )}
        </div>
      </div>

      <div
        className={`modal fade ${
          isModal === "modal-parent" ? "d-block show" : ""
        }`}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Struktur Organisasi (Parent)</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setModal("")}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" style={{ width: "150px" }}>
                    Direksi
                  </span>
                </div>
                <select
                  className="custom-select form-control"
                  id="inputGroupSelect01"
                  value={xDireksi}
                  onChange={(e) => {
                    setxDireksi(e.target.value);
                  }}
                >
                  <option key="-"> - </option>
                  {comboJabatan(dataComboDireksi)}
                </select>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-round btn-default"
                data-dismiss="modal"
                onClick={() => {
                  setModal("");
                  kosongBox();
                }}
              >
                Tutup
              </button>
              <button
                type="button"
                className="btn btn-round btn-primary"
                onClick={() => {
                  simpanParent();
                }}
              >
                Simpan
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal fade ${
          isModal === "modal-bawahan" ? "d-block show" : ""
        }`}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Struktur Organisasi (Child)</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setModal("")}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" style={{ width: "150px" }}>
                    Atasan
                  </span>
                </div>
                <input className="form-control" value={xParent} />
              </div>
              <div className="input-group mb-3">
                {/* <ThemeProvider theme={darkTheme}> */}
                  <Autocomplete
                    id="combo-box-demo"
                    size="small"
                    options={dataComboJabatan}
                    autoHighlight
                    getOptionLabel={(option) => option.jabatan}
                    onInputChange={(e, v) => {
                      setxChild(v);
                    }}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Bawahan"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                {/* </ThemeProvider> */}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-round btn-default"
                data-dismiss="modal"
                onClick={() => {
                  setModal("");
                  kosongBox();
                }}
              >
                Tutup
              </button>
              <button
                type="button"
                className="btn btn-round btn-primary"
                onClick={() => {
                  simpanChild();
                }}
              >
                Simpan
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${
          isModal === "modal-hapus-bawahan" ? "d-block show" : ""
        }`}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Hapus dari Struktur</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setModal("")}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              Apakah anda yakin untuk menghapus "{key.split("~$~")[1]}" dari
              struktur "{key.split("~$~")[0]}" ?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-round btn-default"
                data-dismiss="modal"
                onClick={() => {
                  setModal("");
                  kosongBox();
                }}
              >
                Tutup
              </button>
              <button
                type="button"
                className="btn btn-round btn-danger"
                onClick={() => {
                  deleteStruktur();
                }}
              >
                Hapus
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Struktur;
