import React from "react";
import Tooltip from "../common/toolTip";

const tableBodyStruktur = (loading, dataParent, dataChild, { loadData }, { setModal }) => {
    var renderx = [];
    if (dataParent.length > 0) {
        renderx = dataParent && dataParent.map((key, i) => {
            return (
                <div className="col-lg-12 col-md-12">
                    <div className="card">
                        <div className="body">
                            <div className="media mleft" key={i}>
                                <div className="media-left"><a href="/"><img alt="" className="media-object rounded" src="../assets/images/user.png" width="64" height="64" /></a></div>
                                <div className="media-body">
                                    <h4 className="media-heading">{key}
                                        <span className='float-right'>
                                            <button className='btn btn-outline-warning' title='Tambah Bawahan' onClick={() => {
                                                loadData(key);
                                                setModal('modal-bawahan');
                                            }}>
                                                <i className='fa fa-plus'></i>
                                            </button>
                                        </span>
                                    </h4>

                                    {dataChild.filter((child) => child.parent_jabatan === key).map((child, index) => {
                                        return (
                                            <div className="media m-t-20" key={index}>
                                                <div className="media-left"><a href="/"><img alt="" className="media-object rounded" src="../assets/images/user.png" width="64" height="64" /></a></div>
                                                <div className="media-body">
                                                    <h4 className="media-heading">{child.child_jabatan}
                                                        <span className='float-right'>
                                                            <button className='btn btn-outline-warning' title='Tambah Bawahan' onClick={() => {
                                                                loadData(child.child_jabatan);
                                                                setModal('modal-bawahan');
                                                            }}>
                                                                <i className='fa fa-plus'></i>
                                                            </button>{" "}
                                                            <button className='btn btn-outline-danger' title='Hapus dari Struktur Ini' onClick={() => {
                                                                loadData(child);
                                                                setModal('modal-hapus-bawahan');
                                                            }}>
                                                                <i className='fa fa-minus'></i>
                                                            </button>
                                                        </span>
                                                    </h4>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })

        return renderx;
    } else {
        return (
            <div className="col-lg-12">
                <div className="card">
                    <div className="body">
                        <div className="inline-editor">
                            <p className="m-b-0 text-center">Belum ada data</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export {
    tableBodyStruktur
}