import React, { Component } from 'react';
import MetisMenu from 'react-metismenu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { setOffcanvas } from '../../actions/settingsAction';
import metisMenu from './metisMenu';
import metisMenuPegawai from './metisMenuPegawai';
import metisMenuAtasan from './metisMenuAtasan';
import metisMenuDireksi from './metisMenuDireksi';
import DefaultLink from './DefaultLink';
import { Dropdown } from 'react-bootstrap';
import { setMiniSidebarMenuOn, setMiniHover } from '../../actions/settingsAction';
import { getCookie } from '../../helpers/CookieManager';

import Badge from "../../helpers/SidebarBadge"
import { apiWs } from '../../configs/axios';


class Menu extends Component {
	constructor(props) {
		super(props);

		this.state = {
			npp: '',
			nama: '',
			jabatan: '',
			role: '',
			kantor: '',
			menu: [],
			sidebarCount: {}
		}

		this.toggleSubMenu = this.toggleSubMenu.bind(this);
		this.minisidebarMouseOver = this.minisidebarMouseOver.bind(this);
		this.minisidebarMouseOut = this.minisidebarMouseOut.bind(this);
	}

	menuHandler(role) {
		switch (role) {
			case "pegawai":
				const menuPegawai = [
					{
						id: 1,
						icon: "icon-speedometer",
						label: "Dashboard",
						to: "/dashboard12",
					},
					{
					  id: "tugas",
					  label: "Tugas",
					},
					{
						id: 2,
						icon: "fa fa-list-ol",
						label: <>Histori Tugas {this.state.sidebarCount.histori !== 0 && <Badge count={this.state.sidebarCount.histori} />}</>,
						to: "/tugas/histori"
					},
					{
						id: 3,
						icon: "fa fa-list-ol",
						label: <>Pengajuan Tugas {this.state.sidebarCount.pengajuan !== 0 && <Badge count={this.state.sidebarCount.pengajuan} />}</>,
						to: "/tugas/pengajuan"
					},
					{
						id: 4,
						icon: "fa fa-list-ol",
						label: <>Dalam Proses {this.state.sidebarCount.dalam_proses !== 0 && <Badge count={this.state.sidebarCount.dalam_proses} />}</>,
						to: "/tugas/dalam-proses"
					},
					{
						id: 5,
						icon: "fa fa-list-ol",
						label: <>Tugas Selesai {this.state.sidebarCount.selesai !== 0 && <Badge count={this.state.sidebarCount.selesai} />}</>,
						to: "/tugas/selesai"
					},
				];
				return menuPegawai
				break;

			case "atasan":
				const menuAtasan = [
					{
						id: 1,
						icon: "icon-speedometer",
						label: "Dashboard",
						to: "/dashboard12",
					},
					{
						id: 2,
						icon: "fa fa-history",
						label: "Histori Unit Kerja",
						to: "/histori_unit_kerja",
					},
					{
					  id: "tugas",
					  label: "Tugas",
					},
					{
						id: 3,
						icon: "fa fa-list-ol",
						label: <>Histori Tugas {this.state.sidebarCount.histori !== 0 && <Badge count={this.state.sidebarCount.histori} />}</>,
						to: "/tugas/histori"
					},
					{
						id: 4,
						icon: "fa fa-list-ol",
						label: <>Penugasan {this.state.sidebarCount.penugasan !== 0 && <Badge count={this.state.sidebarCount.penugasan} />}</>,
						to: "/tugas/penugasan"
					},
					{
						id: 5,
						icon: "fa fa-list-ol",
						label: <>Pengajuan Tugas {this.state.sidebarCount.pengajuan !== 0 && <Badge count={this.state.sidebarCount.pengajuan} />}</>,
						to: "/tugas/pengajuan"
					},
					{
						id: 6,
						icon: "fa fa-list-ol",
						label: <>Dalam Proses {this.state.sidebarCount.dalam_proses !== 0 && <Badge count={this.state.sidebarCount.dalam_proses} />}</>,
						to: "/tugas/dalam-proses"
					},
					{
						id: 7,
						icon: "fa fa-list-ol",
						label: <>Tugas Selesai {this.state.sidebarCount.selesai !== 0 && <Badge count={this.state.sidebarCount.selesai} />}</>,
						to: "/tugas/selesai"
					},
					{
					  id: "approval",
					  label: "Approval",
					},
					{
						id: 8,
						icon: "fa fa-users",
						label: <>Diajukan {this.state.sidebarCount.approval_diajukan !== 0 && <Badge count={this.state.sidebarCount.approval_diajukan} />}</>,
						to: "/approval"
					},
					{
						id: 9,
						icon: "fa fa-users",
						label: <>Dikerjakan {this.state.sidebarCount.approval_dikerjakan !== 0 && <Badge count={this.state.sidebarCount.approval_dikerjakan} />}</>,
						to: "/app-project-list"
					},
				];
				return menuAtasan
				break;

			case "direksi":
				const menuDireksi = [
					{
						id: 1,
						icon: "icon-speedometer",
						label: "Dashboard",
						to: "/dashboard12",
					},
				
					// WORKSHEET SECTION
					{
						id: 2,
						icon: "fa fa-history",
						label: "Histori Unit Kerja",
						to: "/histori_unit_kerja",
					},
					{
					  id: "tugas",
					  label: "Tugas",
					},
					{
						id: 3,
						icon: "fa fa-list-ol",
						label: <>Penugasan {this.state.sidebarCount.penugasan !== 0 && <Badge count={this.state.sidebarCount.penugasan} />}</>,
						to: "/tugas/penugasan"
					},
					{
					  id: "approval",
					  label: "Approval",
					},
					{
						id: 4,
						icon: "fa fa-users",
						label: <>Diajukan {this.state.sidebarCount.approval_diajukan !== 0 && <Badge count={this.state.sidebarCount.approval_diajukan} />}</>,
						to: "/approval"
					},
					{
						id: 5,
						icon: "fa fa-users",
						label: <>Dikerjakan {this.state.sidebarCount.approval_dikerjakan !== 0 && <Badge count={this.state.sidebarCount.approval_dikerjakan} />}</>,
						to: "/app-project-list"
					},
				];
				return menuDireksi
				break;

			default:
				return metisMenu
				break;
		}
	}

	toggleSubMenu1(e) {
		console.log(e, 'eeeee')
		// let menucClass = ''
		return "sdsdfszd";
	}
	toggleSubMenu(e) {
		let menucClass = ''
		if (e.itemId) {
			const subClass = e.items.map((menuItem) => {
				if (menuItem.id === 'main' ||
					menuItem.id === 'app' ||
					menuItem.id === 'extra' ||
					menuItem.id === 'ui' ||
					menuItem.id === 'worksheets' ||
					menuItem.id === 'inquiry' ||
					menuItem.id === 'entry' ||
					menuItem.id === 'hukuman_absensi' ||
					menuItem.id === 'laporan' ||
					menuItem.id === 'export' ||
					menuItem.id === 'periode' ||
					menuItem.id === 'master' ||
					menuItem.id === 'tugas' ||
					menuItem.id === 'approval' ||
					menuItem.id === 'sam_kredit')
				{
					menucClass = "header";
				}
				if (menuItem.to === this.props.location.pathname) {
					menucClass = "";
				} else {
					menucClass = "collapse";
				}
				return menucClass;
			})
			return subClass
			// return "collapse";
		} else {
			return e.visible ? "collapse" : "metismenu";
		}
	}

	minisidebarMouseOver() {
		this.props.setMiniSidebarMenuOn(false);
		this.props.setMiniHover(true);
	}

	minisidebarMouseOut() {
		this.props.setMiniSidebarMenuOn(true);
		this.props.setMiniHover(false);
	}

	componentDidMount() {
		// const token = window.sessionStorage.getItem('token')
		const token = getCookie('token')
		const tokenBody = token.split('.')[1];
		const result = JSON.parse(atob(tokenBody))
		// console.log(result);
		this.setState({
			npp: result.sub.npp,
			nama: result.sub.nama,
			jabatan: result.sub.jabatan,
			role: result.sub.role,
			kantor: result.sub.kantor,
		}, () => {
			apiWs.get(`/sidebar/${this.state.role}/${this.state.npp}`).then((res) => { this.setState({sidebarCount: res.data}) })
		})
	}

	componentDidUpdate(prevState){
		if (this.state.role !== "") {
			const getLitext = document.querySelectorAll('#left-sidebar-nav div ul li');
			getLitext.forEach(function (el) {
				if (el.innerText === 'Main' ||
					el.innerText === "App" ||
					el.innerText === "UI Elements" ||
					el.innerText === "Extra" ||
					el.innerText === "Worksheets" ||
					el.innerText === "Inquiry" ||
					el.innerText === "Entry" ||
					el.innerText === "Hukuman & Absen" ||
					el.innerText === "Laporan" ||
					el.innerText === "Export" ||
					el.innerText === "Periode" ||
					el.innerText === "Master" ||
					el.innerText === "Tugas" ||
					el.innerText === "Approval" ||
					el.innerText === "SAM Kredit")
				{
					el.className = "header"
				}
			});
		}
	}
	render() {
		if (document.getElementById('left-sidebar') && this.props.miniSidebar) {
			document.getElementById('left-sidebar').addEventListener('mouseover', this.minisidebarMouseOver);
		}
		if (document.getElementById('left-sidebar') && this.props.miniHover) {
			document.getElementById('left-sidebar').addEventListener('mouseout', this.minisidebarMouseOut);
		}
		return (
			<>
				<div id="left-sidebar" className={`sidebar${this.props.miniSideMenuOn ? ' mini_sidebar_on' : ''}`}>
					<div className="navbar-brand">
						<div className='row'>
							<div className='col'>
								<Link to="/"><img src="../assets/images/logo.png" alt="KPI logo" className="img-fluid logo" /><span><h6 className='mb-0'>Worksheet Bank NTT</h6></span></Link>
							</div>
							<div className='col'>
								<button type="button" className="btn-toggle-offcanvas btn btn-lg float-right" onClick={() => this.props.setOffcanvas(!this.props.offcanvas)} style={{fontSize:25}}><i className="lnr lnr-menu icon-close"></i></button>
							</div>
						</div>
					</div>
					<div className="sidebar-scroll">
						<div className='mx-2 my-3'>
							<strong>{this.state.nama}</strong>
							<div className='mt-2'>{this.state.jabatan}</div>
							<div className='mt-2'>{this.state.kantor}</div>
						</div>
						<nav id="left-sidebar-nav" className="sidebar-nav pb-5 mb-5">
							<MetisMenu
								content={this.menuHandler(this.state.role)}
								noBuiltInClassNames={true}
								classNameItemActive="active"
								classNameContainer={(e) => this.toggleSubMenu(e)}
								classNameContainerVisible="in"
								iconNamePrefix=""
								LinkComponent={(e) => <DefaultLink itemProps={e} />}
								onSelected={() => {
									if(this.props.offcanvas){
										this.props.setOffcanvas(!this.props.offcanvas)
									}
								}}
							/>
						</nav>
					</div>
				</div>

			</>
		)
	}
}

const mapStateToProps = state => ({
	offcanvas: state.settings.offcanvas,
	miniSidebar: state.settings.miniSidebar,
	miniSideMenuOn: state.settings.miniSideMenuOn,
	miniHover: state.settings.miniHover,
})

const mapDispatchToProps = dispatch => ({
	setOffcanvas: (e) => dispatch(setOffcanvas(e)),
	setMiniSidebarMenuOn: (e) => dispatch(setMiniSidebarMenuOn(e)),
	setMiniHover: (e) => dispatch(setMiniHover(e)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Menu);