import React from "react";

const comboJabatan=(ar)=>{
    return ar && ar.map((key, index)=>{
		return(
			<option key={"option_jabatan_"+index} value={key.jabatan}>{key.jabatan}</option>
		)
	})
};

export{
    comboJabatan
};