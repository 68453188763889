import React, { useState, useEffect } from "react";
import Nestable from "react-nestable";
import { api, apiWs } from "../../configs/axios";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  TabelWorksheet,
  JenisHandler,
  StatusHandler,
  DateFormatter,
} from "./TabelWorksheet";

// IMPORT AUTOCOMPLETE
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { getCookie } from "../../helpers/CookieManager";
import ReactExport from "react-export-excel";
import LoadingScreen from 'react-loading-screen';
import "flatpickr/dist/themes/airbnb.css";
import Flatpickr from "react-flatpickr";
import { toastMessageCall } from "../../helpers/Utils";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Taskboard = () => {
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  // const token = window.sessionStorage.getItem("token");
  const token = getCookie("token");
  const tokenBody = token.split(".")[1];
  const result = JSON.parse(atob(tokenBody));

  let kantor_induk_default = ""
  if (result.sub.role !== "direksi" && result.sub.role !== "admin") {
    kantor_induk_default = result.sub.kantor_induk
  }

  const [tglStart, setTglStart] = useState(null);
  const [tglEnd, setTglEnd] = useState(null);

  const [kantorInduk, setKantorInduk] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [sessionCred, setSessionCred] = useState(result.sub);
  const [selectedKantorInduk, setSelectedKantorInduk] = useState(kantor_induk_default);
  const [isModal, setModal] = useState("");
  const [data, setData] = useState([]);
  const [tableHeader, setTableHeader] = useState(
    sessionCred.role === "admin" || sessionCred.role === "direksi"
      ? [
        { field: "nama_pegawai", headerName: "Nama Pegawai" },
        { field: "jabatan_pegawai", headerName: "Jabatan Pegawai" },
        { field: "kantor", headerName: "Kantor" },
        { field: "kantor_induk", headerName: "Kantor Induk" },
        {
          field: "status",
          headerName: "Status",
          cellRendererFramework: (params) => (
            <StatusHandler status={params.data.status} />
          ),
        },
        { field: "judul", headerName: "Tugas" },
        {
          field: "jenis",
          headerName: "Jenis Tugas",
          cellRendererFramework: (params) => (
            <JenisHandler jenis={params.data.jenis} />
          ),
        },
        {
          field: "start",
          headerName: "Tanggal Penugasan",
          type: "dateColumn",
          valueFormatter: (params) => DateFormatter(params.data.start),
        },
        {
          field: "end",
          headerName: "Tanggal Akhir",
          type: "dateColumn",
          valueFormatter: (params) => DateFormatter(params.data.end),
        },
        {
          field: "exp",
          headerName: "Tanggal Expired",
          type: "dateColumn",
          valueFormatter: (params) => DateFormatter(params.data.exp),
        },
        {
          field: "id_tugas",
          headerName: "Aksi",
          cellRendererFramework: (params) => (
            <Link
              className="btn btn-sm btn-info"
              to={`/detail-project-list/${params.data.id_tugas}`}
            >
              Detail
            </Link>
          ),
        },
      ]
      : [
        { field: "nama_pegawai", headerName: "Nama Pegawai" },
        { field: "jabatan_pegawai", headerName: "Jabatan Pegawai" },
        {
          field: "status",
          headerName: "Status",
          cellRendererFramework: (params) => (
            <StatusHandler status={params.data.status} />
          ),
        },
        { field: "judul", headerName: "Tugas" },
        {
          field: "jenis",
          headerName: "Jenis Tugas",
          cellRendererFramework: (params) => (
            <JenisHandler jenis={params.data.jenis} />
          ),
        },
        {
          field: "start",
          headerName: "Tanggal Penugasan",
          type: "dateColumn",
          valueFormatter: (params) => DateFormatter(params.data.start),
        },
        {
          field: "end",
          headerName: "Tanggal Akhir",
          type: "dateColumn",
          valueFormatter: (params) => DateFormatter(params.data.end),
        },
        {
          field: "exp",
          headerName: "Tanggal Expired",
          type: "dateColumn",
          valueFormatter: (params) => DateFormatter(params.data.exp),
        },
        {
          field: "id_tugas",
          headerName: "Aksi",
          cellRendererFramework: (params) => (
            <Link
              className="btn btn-sm btn-info"
              to={`/detail-project-list/${params.data.id_tugas}`}
            >
              Detail
            </Link>
          ),
        },
      ]
  );

  const _getData = () => {
    const params = {
      start: tglStart,
      end: tglEnd,
      kantor: selectedKantorInduk
    }
    setIsLoading(true)
    apiWs.get("/tugas/history-by-unit", {
      params
    })
      .then((res) => {
        var ar = [];
        if (res.data.data.length) {
          res.data.data.map((item) => {
            ar.push(JSON.parse(item));
          });
        }
        setData(ar)
        // ar.sort(function (x, y) {
        //   return new Date(x.start) < new Date(y.start) ? 1 : -1;
        // });
        // if (sessionCred.role === "admin" || sessionCred.role === "direksi") {
        //   if (!selectedKantorInduk) {
        //     setData(
        //       ar.filter((d) => d.status !== "Menunggu" && d.status !== "Deleted")
        //     );
        //   } else {
        //     setData(
        //       ar.filter(
        //         (d) =>
        //           d.status !== "Menunggu" &&
        //           d.status !== "Deleted" &&
        //           d.kantor_induk === selectedKantorInduk
        //       )
        //     );
        //   }
        // } else {
        //   setData(
        //     ar.filter(
        //       (d) =>
        //         d.status !== "Menunggu" &&
        //         d.status !== "Deleted" &&
        //         d.kantor_induk === sessionCred.kantor_induk
        //     )
        //   );
        // }
      })
      .catch(err => {
        toastMessageCall("Gagal mendapatkan data", "top-center", "error");
        console.error(err.response);
      })
      .finally(() => {
        setIsLoading(false)
      })
  };

  function _getKantorInduk() {
    api.get("/kantor").then((res) => {
      var ar = [];
      if (res.data.data.length) {
        res.data.data.map((item) => {
          ar.push(JSON.parse(item).kantor_induk);
        });
      }
      ar = [...new Set(ar)];
      setKantorInduk(ar);
    });
  }

  function customHistoryHandler(e) {
    e.preventDefault();
    _getData();
  }

  function ExcelExport({ data }) {
    return (
      <ExcelFile
        filename={
          selectedKantorInduk
            ? `Histori Unit Kerja - ` + selectedKantorInduk
            : `Histori Unit Kerja`
        }
        element={
          <button
            type="button"
            className="btn btn-sm btn-success btn-block mr-1"
          >
            Export Data histori Unit Kerja ke Excel
          </button>
        }
      >
        <ExcelSheet
          data={data}
          name={
            selectedKantorInduk
              ? `Histori Unit Kerja - ` + selectedKantorInduk
              : `Histori Unit Kerja`
          }
        >
          <ExcelColumn label="Judul Tugas" value="judul" />
          <ExcelColumn label="Jenis Tugas" value="jenis" />
          <ExcelColumn label="Nama Pegawai" value="nama_pegawai" />
          <ExcelColumn label="Jabatan Pegawai" value="jabatan_pegawai" />
          <ExcelColumn label="Nama Atasan" value="nama_atasan" />
          <ExcelColumn label="Jabatan Atasan" value="jabatan_atasan" />
          <ExcelColumn label="Kantor" value="kantor" />
          <ExcelColumn label="Kantor Induk" value="kantor_induk" />
          <ExcelColumn label="Tanggal Penugasan" value="start" />
          <ExcelColumn label="Tanggal Akhir" value="end" />
          <ExcelColumn label="Tanggal Expired" value="exp" />
          <ExcelColumn label="Status" value="status" />
        </ExcelSheet>
      </ExcelFile>
    );
  }

  useEffect(() => {
    // _getData();
    _getKantorInduk();
  }, []);

  return (
    <LoadingScreen
      loading={isLoading}
      bgColor='#f1f1f1'
      spinnerColor='#9ee5f8'
      textColor='#676767'
      logoSrc="../../assets/images/Logo-bankNTT1.png"
      text=''
    >
      <div className="container-fluid">
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h2>Histori Unit Kerja</h2>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Worksheet</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Histori Unit Kerja
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div className="row clearfix">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="card">
              <div className="body taskboard b-cyan planned_task">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend w-70">
                        <span className="input-group-text" style={{ width: '150px' }}>Tanggal Mulai</span>
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-6">
                        <Flatpickr
                          options={{
                            altInput: true,
                            altFormat: "j F Y",
                            dateFormat: "Y-m-d",
                            onChange: (selectedDates, dateStr, instance) => {
                              setTglStart(dateStr);
                            },
                          }}
                          style={{ width: '150px', backgroundColor: `transparent` }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend w-70">
                        <span className="input-group-text" style={{ width: '150px' }}>Tanggal Selesai</span>
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-6">
                        <Flatpickr
                          // className='form-control'
                          options={{
                            altInput: true,
                            altFormat: "j F Y",
                            dateFormat: "Y-m-d",
                            onChange: (selectedDates, dateStr, instance) => {
                              setTglEnd(dateStr);
                            },
                          }}
                          style={{ width: '150px', backgroundColor: `transparent` }}
                        />
                      </div>
                    </div>
                  </div>
                  {(sessionCred.role === "admin" ||
                    sessionCred.role === "direksi") && (
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="input-group mb-3">
                          <div className="input-group-prepend w-70">
                            <span className="input-group-text" style={{ width: '150px' }}>Kantor Induk</span>
                          </div>
                          <div className="col-lg-3 col-md-4 col-sm-6">
                            <Autocomplete
                              id="combo-box-demo"
                              size="small"
                              options={kantorInduk}
                              autoHighlight
                              value={selectedKantorInduk || null}
                              onChange={(e, v) => {
                                setSelectedKantorInduk(v);
                              }}
                              sx={{ width: "100%" }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password", // disable autocomplete and autofill
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                </div>
                <div className="row">
                  <div className="col">
                    <b>Note: Tanggal diambil dari Tanggal Penugasan</b>
                  </div>
                  <div className="col">
                    <div className="input-group text-right" >
                      <button onClick={() => _getData()} className="btn btn-md btn-primary start-end" style={{ marginLeft: '90%' }}>Cari</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row clearfix">
          <div className="col-md-6 col-sm-12">
            <div className="d-flex justify-content-start">
              <ExcelExport data={data} />
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12">
            <TabelWorksheet
              tableData={data}
              tableHeader={tableHeader}
              tableTitle={`Tabel Tugas yang Dikerjakan Bawahan`}
            />
          </div>
        </div>
      </div>
    </LoadingScreen>
  );
};

// const OldTableLayout = () => {
//   <div className="card">
//     <div className="body taskboard b-cyan planned_task">
//       <h6 className="font300 mb-3">Tabel Histori Unit Kerja</h6>
//       <div className="table-responsive">
//         <table className="table table-hover table-custom spacing8">
//           <thead>
//             <tr className="text-center">
//               <th>Nama</th>
//               <th>Jabatan</th>
//               <th>Status</th>
//               <th>Tugas</th>
//               <th>Jenis</th>
//               <th>Tanggal Penugasan</th>
//               <th>Tanggal Akhir</th>
//               <th>Aksi</th>
//             </tr>
//           </thead>
//           <tbody>
//             {data.filter((d) => d.status !== "Menunggu").map((e, i) => (
//               <tr key={i} className="text-center">
//                 <td>{e.nama_pegawai}</td>
//                 <td>Jabatan</td>
//                 <td>
//                   <StatusHandler status={e.status} />
//                 </td>
//                 <td><span>{e.judul}</span></td>
//                 <td>
//                   {e.jenis === "Tugas Utama" && <span className="badge badge-success">Tugas Utama/RBB</span>}
//                   {e.jenis === "Tugas Tambahan" && <span className="badge badge-primary">Tugas Khusus Tambahan</span>}
//                   {e.jenis === "Tugas Rutin" && <span className="badge badge-info">Tugas Rutin Harian</span>}
//                 </td>
//                 <td><span>{moment(e.start).format('D MMMM YYYY')}</span></td>
//                 <td><span>{moment(e.end).format('D MMMM YYYY')}</span></td>
//                 <td>
//                   <Link className='btn btn-sm btn-info' to={`/detail-project-list/${e.id}`}>Detail</Link>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   </div>
// }

export default Taskboard;
