import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { apiWs } from '../../configs/axios';
import { toastMessageCall } from '../../helpers/Utils';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setCookie } from '../../helpers/CookieManager';

const tahun = new Date().getFullYear();

export default class Login extends Component {

	state = {
		npp: '',
		pwd: '',
		isLogin: false
	}

	setLogin = (status) => {
		this.setState({
			isLogin:status
		})
	}

	authenticate = (e) => {
		e.preventDefault()
		const { npp, pwd } = this.state
		if (!npp) {
			toastMessageCall('NPP tidak boleh kosong.', 'top-center', 'warning');
			return false;
		}

		if (!pwd) {
			toastMessageCall('Password tidak boleh kosong.', 'top-center', 'warning');
			return false;
		}
		this.setLogin(true)
		// console.log(npp);
		// return false

		apiWs.post('/login', {
			npp,
			pwd
		})
			.then(res => {
				if (res.data.message == "no user") {
					toastMessageCall('Login bermasalah, perbaiki input NPP atau password!', 'top-center', 'error');
				} else if(res.data.message == "wrong pass"){
					toastMessageCall('Login bermasalah, perbaiki input NPP atau password!', 'top-center', 'error');

				} else if(res.data.message == "has no role"){
					toastMessageCall('Jabatan dari NPP yang diinput tidak memiliki atasan maupun bawahan. Silakan hubungi ADMINISTRATOR.', 'top-center', 'error');

				} else {
					toastMessageCall('Login berhasil. Mohon menunggu anda akan diarahkan kehalaman utama.', 'top-center', 'success');
					// window.sessionStorage.setItem('token', res.data.token)
					setCookie('token', res.data.token)
					const queryString = window.location.search;
					const urlParams = new URLSearchParams(queryString);
					const redirectTo = urlParams.get('x')
					// console.log(redirectTo);
					window.location = redirectTo
				}
				this.setLogin(false)
			})
			.catch(err => {
				toastMessageCall('err', 'top-center', 'error');
				this.setLogin(false)
				return false;
			})
	}

	render() {
		return (
			<>
			<ToastContainer/>
				<div className="pattern">
					<span className="red"></span>
					<span className="indigo"></span>
					<span className="blue"></span>
					<span className="green"></span>
					<span className="orange"></span>
				</div>
				<div className="auth-main particles_js">
					<div className="auth_div vivify popIn">
						<div className="auth_brand">
							<Link className="navbar-brand" to="/">
								<img src="../assets/images/Logo-bankNTT1.png" width="200" height="150" className="d-inline-block align-top mr-2" alt="Oculux logo" />
							</Link>
						</div>
						<div className="card">
							<div className="body">
								<p className="lead text-dark"><h4>Worksheet Bank NTT</h4></p>
								<form className="form-auth-small m-t-20" onSubmit={e => this.authenticate(e)}>
									<div className="form-group">
										<label htmlFor="signin-email" className="control-label sr-only">NPP</label>
										<input type="text" className="form-control round" placeholder="NPP" value={this.state.npp} onChange={e => this.setState({ npp: e.target.value })} />
									</div>
									<div className="form-group">
										<label htmlFor="signin-password" className="control-label sr-only">Password</label>
										<input type="password" className="form-control round" placeholder="Password" value={this.state.pwd} onChange={e => this.setState({ pwd: e.target.value })} />
									</div>
									<button type="submit" disabled={this.state.isLogin} className="btn btn-primary btn-round btn-block">{this.state.isLogin ? <i className="fa fa-spinner fa-spin"></i> : "LOGIN"}</button>
									<div className="bottom">
										<span>PT. Bank Pembangunan Daerah<br />Nusa Tenggara Timur<br />&copy; {tahun}</span>
									</div>
								</form>
							</div>
						</div>
					</div>
					<div id="particles-js"></div>
				</div>
			</>
		);
	}
}
