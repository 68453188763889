import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { api, apiWs } from '../../configs/axios';
import {
    defaultLimit,
    toastMessageCall,
} from '../../helpers/Utils';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { deleteCookie, getCookie } from '../../helpers/CookieManager';

const PageProfile = () => {
    const [xId, setxId] = useState('');
    const [xNpp, setxNpp] = useState('');
    const [xNama, setxNama] = useState('');
    const [xKantor, setxKantor] = useState('');
    const [xJabatan, setxJabatan] = useState('');
    const [xpassword, setxPassword] = useState('');
    const [cpassword, setcPassword] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault()

        if (!xpassword) {
            toastMessageCall('Passwordnya jangan dikosongin dong bro...', 'top-center', 'error');
            return false;
        }
        if (xpassword !== cpassword) {
            toastMessageCall('Password & konfirmasi password tidak sama!', 'top-center', 'error');
            return false;
        }

        apiWs.post(`/change-pass/${xId}`, {
            pwd: xpassword
        })
            .then(res => {
                toastMessageCall('Password berhasil diubah', 'top-center', 'success');
                // window.sessionStorage.clear()
                deleteCookie('token')
                window.location = '/'
            })
            .catch(err => {
                toastMessageCall('Password gagal diubah', 'top-center', 'error');
                return false
            })
    }

    useEffect(() => {
        // const token = window.sessionStorage.getItem('token')
        const token = getCookie('token')
        const tokenBody = token.split('.')[1];
        const result = JSON.parse(atob(tokenBody))
        setxId(result.sub.id_pegawai)
        setxNama(result.sub.nama)
        setxNpp(result.sub.npp)
        setxJabatan(result.sub.jabatan)
        setxKantor(result.sub.kantor)
    }, [])
    return (
        <>
            <div className="container-fluid">
                <div className="block-header">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <h2>Profile</h2>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Worksheet</Link></li>
                                    <li className="breadcrumb-item"><a href="/">User</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Profile</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-md-6 col-sm-12 text-right hidden-xs">
                        </div>
                    </div>
                </div>
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card social">
                            <div className="profile-header d-flex justify-content-between justify-content-center">
                                <div className="d-flex">
                                    <div className="mr-3">
                                        <img src="../assets/images/user.png" className="rounded" alt="Avatar" />
                                    </div>
                                    <div className="details">
                                        <h5 className="mb-0">{xNama}</h5>
                                        <span className="text-light">{xNpp}</span><br />
                                        <span className="text-light">{xJabatan}</span><br />
                                        <span className="text-light">{xKantor}</span>
                                    </div>
                                </div>
                                {/* <div>
                                    <button className="btn btn-success btn-sm"><i className='icon-bubbles'></i> Kirim Pesan</button>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12 col-lg-12 col-md-12">
                        <div className="card">
                            <div className="header">
                                <h2></h2>
                            </div>
                            <div className="body">
                                <form onSubmit={(e) => handleSubmit(e)}>
                                    <div className="row clearfix">
                                        <div className="col-lg-12 col-md-12">
                                            <h6>Ubah Password</h6>
                                            <div className="form-group">
                                                <input type="password" className="form-control" placeholder="Password baru" value={xpassword} onChange={(e) => setxPassword(e.target.value)} />
                                            </div>
                                            <div className="form-group">
                                                <input type="password" className="form-control" placeholder="Ketik ulang password baru" value={cpassword} onChange={(e) => setcPassword(e.target.value)} />
                                            </div>
                                            {xpassword !== cpassword && <div className="alert alert-warning">Password & konfirmasi password tidak sama, mohon diperiksa kemnbali</div>}
                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-round btn-primary mr-1" style={{ float: 'right' }} disabled={xpassword !== cpassword}>Simpan</button> &nbsp;&nbsp;
                                    <button type="button" className="btn btn-round btn-default" style={{ float: 'right' }} onClick={() => window.history.go(-1)}>Batal</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}
export default PageProfile
