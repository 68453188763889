import React, { Component } from 'react';
import { connect } from 'react-redux';
import './App.css';
import Layout from './components/Shared/Layout';
import Login from './components/Authentication/login';
import SignUp from './components/Authentication/signup';
import ForgotPassword from './components/Authentication/forgotpassword';
import NotFound from './components/Authentication/404';
import Maintenance from './components/Authentication/maintenance';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { apiWs } from './configs/axios';
import { ToastContainer } from 'react-toastify';
import {
	toastMessageCall,
} from './helpers/Utils';
import 'react-toastify/dist/ReactToastify.css';
import { deleteCookie, getCookie } from './helpers/CookieManager';

class App extends Component {

	constructor(props) {
		super(props);
		this.state = {
			primary: '',
			xid: '',
			xpassword: '',
			cpassword: '',
			xstatus: ''
		}
	}

	checkStatus() {
		this.state.xstatus === true &&
			this.setState({
				primary: 'modal-reset'
			})
	}

	handleChange = e => {
		const name = e.target.name
		this.setState({
			[name]: e.target.value
		})
	}

	handleSubmit = (e) => {
		e.preventDefault()

		if (!this.state.xpassword) {
			toastMessageCall('Passwordnya jangan dikosongin dong bro...', 'top-center', 'error');
			return false;
		}
		if (this.state.xpassword !== this.state.cpassword) {
			toastMessageCall('Password & konfirmasi password tidak sama!', 'top-center', 'error');
			return false;
		}

		apiWs.post(`/change-pass/${this.state.xid}`, {
			pwd: this.state.xpassword
		})
			.then(res => {
				toastMessageCall('Password berhasil diubah', 'top-center', 'success');
				this.setState({
					primary: ''
				})
				// window.sessionStorage.clear()
				deleteCookie('token')
				window.location = '/'
			})
			.catch(err => {
				toastMessageCall('Password gagal diubah', 'top-center', 'error');
				return false
			})
	}

	componentDidMount() {
		// const token = window.sessionStorage.getItem('token')
		const token = getCookie('token')
		if (token) {
			const tokenBody = token.split('.')[1];
			const result = JSON.parse(atob(tokenBody))
			const id = result.sub.id_pegawai;
			const status = result.sub.status;
			this.setState({
				xstatus: status,
				xid: id
			}, () => {
				this.checkStatus(this.state.xstatus)
			})
		}
		// document.addEventListener('contextmenu', event => event.preventDefault());
		// document.onkeydown = (e) => {
		// 	if (e.key == 123) {
		// 		e.preventDefault();
		// 	}
		// 	if (e.key == 'F12') {
		// 		e.preventDefault();
		// 	}
		// 	if (e.ctrlKey && e.shiftKey && e.key == 'I') {
		// 		e.preventDefault();
		// 	}
		// 	if (e.ctrlKey && e.shiftKey && e.key == 'C') {
		// 		e.preventDefault();
		// 	}
		// 	if (e.ctrlKey && e.shiftKey && e.key == 'J') {
		// 		e.preventDefault();
		// 	}
		// 	if (e.ctrlKey && e.key == 'U') {
		// 		e.preventDefault();
		// 	}
		// };
	}

	render() {
		const { themeColor, fontStyle, lightVersion, RtlVersion, offcanvas, miniSidebar, horizontalMenu, miniHover } = this.props
		document.getElementsByTagName('body')[0].className = `${themeColor} ${fontStyle}${lightVersion ? ' light_version' : ''}${RtlVersion ? ' rtl' : ''}${offcanvas ? ' offcanvas-active' : ''}${horizontalMenu ? ' h-menu' : ''}${miniSidebar ? ' mini_sidebar' : ''}${miniHover ? ' mini_hover' : ''}`;

		let homeRoute = (<Route component={Layout} />)
		if (!getCookie('token')) {
			const location  = window.location.pathname
			homeRoute = (
				<Route path="/">
					<Redirect to={`/login?x=${location}`} />
				</Route>
			)
		}
		return (
			<div
				ref={leftSidebar => {
					this.leftSidebar = leftSidebar;
				}}
			>
				<ToastContainer />
				<Router>
					<Switch>
						<Route path="/signup" component={SignUp} />
						<Route path="/login" component={Login} />
						<Route path="/forgotpassword" component={ForgotPassword} />
						<Route path="/notfound" component={NotFound} />
						<Route path="/maintenance" component={Maintenance} />
						{homeRoute}
					</Switch>
					<div className={`modal fade bd-example-modal-lg ${this.state.primary === 'modal-reset' ? 'd-block show' : ''}`} id="exampleModal">
						<div className="modal-dialog modal-lg" role="document">
							<form onSubmit={(e) => this.handleSubmit(e)}>
								<div className="modal-content">
									<div className="modal-header">
										<h5 className="modal-title" id="exampleModalLabel">Ganti Password</h5>
										<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => {
											this.setState({
												primary: ''
											})
										}}>
											<span aria-hidden="true">×</span>
										</button>
									</div>
									<div className="modal-body">
										<div className="alert alert-warning">
											Akun anda terdeteksi masih menggunakan password default. Kami sarankan segera mengganti password anda agar lebih aman.
										</div>
										<div className="input-group mb-3">
											<div className="input-group-prepend">
												<span className="input-group-text" style={{ width: '200px' }}>Password Baru</span>
											</div>
											<input type='password' name='xpassword' value={this.state.xpassword} onChange={(e) => this.handleChange(e)} className="form-control" />
										</div>
										<div className="input-group mb-3">
											<div className="input-group-prepend">
												<span className="input-group-text" style={{ width: '200px' }}>Konfirmasi Password</span>
											</div>
											<input type='password' name='cpassword' value={this.state.cpassword} onChange={(e) => this.handleChange(e)} className="form-control" />
										</div>
										{this.state.xpassword !== this.state.cpassword && <div className="alert alert-warning">Password & konfirmasi password tidak sama, mohon diperiksa kemnbali</div>}
									</div>
									<div className="modal-footer">
										<button type="submit" className="btn btn-round btn-info" disabled={this.state.xpassword !== this.state.cpassword}>Simpan</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</Router>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	themeColor: state.settings.themeColor,
	fontStyle: state.settings.fontStyle,
	lightVersion: state.settings.lightVersion,
	RtlVersion: state.settings.RtlVersion,
	offcanvas: state.settings.offcanvas,
	horizontalMenu: state.settings.horizontalMenu,
	miniSidebar: state.settings.miniSidebar,
	miniHover: state.settings.miniHover,
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(App)