import React, { Component } from "react";
import { Link } from "react-router-dom";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Multiselect } from "multiselect-react-dropdown";
import { Button, FormGroup } from "react-bootstrap";
import { api, apiWs } from "../../configs/axios";
import { defaultLimit, toastMessageCall, encrypt } from "../../helpers/Utils";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import "flatpickr/dist/themes/airbnb.css";
import Flatpickr from "react-flatpickr";
import "../../FPOverride.css";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { getCookie } from "../../helpers/CookieManager";
import LoadingScreen from "react-loading-screen";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});
class CreateProjectList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      judul: "",
      kategori: "",
      jenis: "",
      start: "",
      end: "",
      tugas: "",
      pegawai: [],
      atasan: "",
      isLoading: false,
      data_jenis: [],
      data_pegawai: [],
      pegawai_dikecualikan: [],
      expired: "",
      session_credential: {},
      pegawaiComboKey: "newPegawaiByKantorInduk",
      jabatanDireksi: [
        "Direktur Dana & Treasury",
        "Direktur Kepatuhan",
        "Direktur Kredit",
        "Direktur TI & Operasional",
        "Direktur Utama",
      ],
      whiteListJabatan: [],
      submitting: false,
      file: "",
      hariLibur: [],
    };

    this.fileInput = React.createRef();
  }

  handleChangeInput = (e) => {
    const name = e.target.name;
    this.setState(
      {
        [name]: e.target.value,
      },
      () => {
        if (name === "jenis") {
          document.getElementById("form").reset();
          this.setState({
            judul: "",
            start: "",
            end: "",
            tugas: "",
            pegawai: [],
            atasan: "",
            expired: "",
          });
          this.loadPegawai()
        }
      }
    );
  };

  handleChange = (e) => {
    // console.log(e);
    let value = Array.from(e.target.selectedOptions, (option) =>
      option.value.split(";")
    );
    this.setState({ pegawai: value });
  };

  handleExp = (start, end) => {
    if (start === '' || end === '') {
      return false
    }

    const param = {
      start: start,
      end: end,
    };

    if (this.state.jenis === "Tugas Event") {
      apiWs.post("/get-expired-date-event", param).then((res) => {
        // console.log(res.data.expiredDate);
        this.setState({ expired: res.data.expiredDate });
      });
    } else {
      apiWs.post("/get-expired-date", param).then((res) => {
        // console.log(res.data.expiredDate);
        this.setState({ expired: res.data.expiredDate });
      });
    }
  };

  _loading(status) {
    this.setState({
      isLoading: status,
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { judul, jenis, start, end, tugas, pegawai, atasan, expired, file } =
      this.state;

    if (start > end) {
      toastMessageCall(
        "Tanggal mulai tidak boleh lebih dari tanggal akhir",
        "top-center",
        "warning"
      );
      return false;
    }

    if (!judul) {
      toastMessageCall("Judul tidak boleh kosong.", "top-center", "warning");
      return false;
    }
    if (jenis.length === 0) {
      toastMessageCall("Jenis tidak boleh kosong.", "top-center", "warning");
      return false;
    }
    if (!start) {
      toastMessageCall(
        "Tanggal mulai tidak boleh kosong.",
        "top-center",
        "warning"
      );
      return false;
    }
    if (!end) {
      toastMessageCall(
        "Tanggal akhir tidak boleh kosong.",
        "top-center",
        "warning"
      );
      return false;
    }
    if (!tugas) {
      toastMessageCall("Uraian tidak boleh kosong.", "top-center", "warning");
      return false;
    }
    if (pegawai.length === 0 && jenis !== "Tugas Event") {
      toastMessageCall("Pegawai tidak boleh kosong.", "top-center", "warning");
      return false;
    }

    if (file.length > 5) {
      toastMessageCall(
        "Maksimum file yang dapat di upload adalah 5 buah!",
        "top-center",
        "warning"
      );
      return false;
    }

    const param = {
      judul,
      jenis,
      start,
      end,
      atasan: this.state.session_credential,
      tugas,
      pegawai: pegawai.map((eachPegawai) => [
        eachPegawai[6],
        eachPegawai[2],
        eachPegawai[3],
        eachPegawai[1],
      ]),
      exp: expired,
      file: file,
    };

    this.setState({ submitting: true });
    if (jenis === "Tugas Event") {
      const toastEventLoading = toast(
        "Sedang membuat tugas event, silakan tunggu...",
        {
          type: "dark",
          autoClose: false,
          position: "top-center",
          transition: Zoom,
        }
      );
      param.pegawai = [...this.state.data_pegawai];
      param.pegawai_dikecualikan = this.state.pegawai_dikecualikan;

      param.pegawai_dikecualikan.map((pegawai, index) => {
        const indexPegawai = param.pegawai.indexOf(pegawai);
        param.pegawai.splice(indexPegawai, 1);
      });

      if (this.state.session_credential.role === "admin") {
        apiWs
          .post("/create-tugas-event-by-admin", param)
          .then((res) => {
            toast.dismiss(toastEventLoading);
            toastMessageCall("Berhasil membuat tugas", "top-center", "success");
            window.location.reload();
          })
          .catch((err) => {
            toast.dismiss(toastEventLoading);
            toastMessageCall("Gagal membuat tugas", "top-center", "error");
          })
          .finally(() => {
            this.setState({ submitting: false });
          });
      } else {
        apiWs
          .post("/create-tugas-event", param)
          .then((res) => {
            toast.dismiss(toastEventLoading);
            toastMessageCall("Berhasil membuat tugas", "top-center", "success");
            window.location.reload();
          })
          .catch((err) => {
            toast.dismiss(toastEventLoading);
            toastMessageCall("Gagal membuat tugas", "top-center", "error");
          })
          .finally(() => {
            this.setState({ submitting: false });
          });
      }
    } else {
      if (file.length) {
        const form = new FormData();
        form.append("param", JSON.stringify(param));

        for (let i = 0; i < file.length; i++) {
          let berkas = file[i];
          form.append("files", berkas);
        }

        // console.log(...form);

        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };

        apiWs
          .post("/create-tugas-with-file", form, config)
          .then((res) => {
            toastMessageCall("Berhasil membuat tugas", "top-center", "success");
            window.location.reload();
          })
          .catch((err) => {
            toastMessageCall("Gagal membuat tugas", "top-center", "error");
          })
          .finally(() => {
            this.setState({ submitting: false });
          });
      }
      else {
        apiWs
          .post("/create-tugas", param)
          .then((res) => {
            toastMessageCall("Berhasil membuat tugas", "top-center", "success");
            window.location.reload();
          })
          .catch((err) => {
            toastMessageCall("Gagal membuat tugas", "top-center", "error");
          })
          .finally(() => {
            this.setState({ submitting: false });
          });
      }
    }
  };

  getData() {
    apiWs.get("/jenis-tugas").then((res) => {
      if (res.data.result) {
        var ar = [];
        if (res.data.data.length) {
          res.data.data.map((item) => {
            ar.push(JSON.parse(item));
          });
        }

        if (this.state.session_credential.role === "admin") {
          ar = ar.filter((item) => item.jenis_tugas === "Tugas Event");
        } else if (!this.state.whiteListJabatan.includes(this.state.session_credential.jabatan)) {
          ar = ar.filter((item) => item.jenis_tugas !== "Tugas Event");
        }
        // console.log(ar);
        this.setState({
          data_jenis: ar,
        });
      }
    });

    this.loadPegawai()

    apiWs
      .get("/hari-libur")
      .then(res => {
        // console.log(res);
        let hariLiburList = []
        res.data.data.map((v, i) => hariLiburList.push(v[0]))
        this.setState({ hariLibur: hariLiburList })
      })
      .catch(error => {
        console.log(error);
      })
  }

  loadPegawai() {
    const session_credential = {
      id_pegawai: this.state.session_credential.id_pegawai,
      npp: this.state.session_credential.npp,
      nama: this.state.session_credential.nama,
      jabatan: this.state.session_credential.jabatan,
      kantor: this.state.session_credential.kantor,
      role: this.state.session_credential.role,
    };

    let key;
    if (session_credential.role === "admin" || (session_credential.role === 'direksi' && this.state.jenis === "Tugas Tambahan")) {
      key = "allPegawai";
    } else {
      key = this.state.pegawaiComboKey;
    }

    // LOAD PEGAWAI
    var param = {
      key:
        key +
        "$" +
        session_credential.id_pegawai +
        "$" +
        session_credential.npp +
        "$" +
        session_credential.nama +
        "$" +
        session_credential.jabatan +
        "$" +
        session_credential.kantor +
        "$" +
        session_credential.role,
    };

    api
      .get("/pegawai/data/combo", { params: param })
      .then((res) => {
        if (!res.data.result) {
          toastMessageCall(res.data.message, "top-center", "warning");
          return false;
        }

        var ar = [];
        if (res.data.data.length) {
          res.data.data.map((item, i, row) => {
            if (
              item[5] !== this.state.session_credential.id_pegawai &&
              !this.state.jabatanDireksi.includes(item[2])
            ) {
              ar.push(item);
            }
          });
        }

        ar.sort(function (x, y) {
          return x[6] < y[6] ? -1 : 1;
        });
        this.setState({ data_pegawai: ar });
      })
      .catch((err) => {
        toastMessageCall("Gagal terhubung dengan backend. Error : " + err);
      });
  }

  loadJabatanEvent() {
    this._loading(true);
    api
      .get("/jabatan/event")
      .then((res) => {
        let whiteListJabatan = [];
        if (res.data.data.length) {
          res.data.data.map((item) => {
            whiteListJabatan.push(item[0]);
          });
          this.setState({ whiteListJabatan: whiteListJabatan }, () => {
            this.getData();
          });
          this._loading(false);
        }
      })
      .catch((err) => { });
  }

  handleFileChange = (e) => {
    this.setState({ file: e.target.files }, () => {
      if (this.state.file.length > 5) {
        toastMessageCall(
          "Maksimum file yang dapat di upload adalah 5 buah!",
          "top-center",
          "warning"
        );
      }
    });
  };

  componentDidMount() {
    // const token = window.sessionStorage.getItem("token");
    const token = getCookie("token");
    const tokenBody = token.split(".")[1];
    const result = JSON.parse(atob(tokenBody));
    this.setState(
      {
        session_credential: result.sub,
      },
      () => {
        this.loadJabatanEvent();
      }
    );
  }

  componentDidUpdate() {
    console.log(this.state.expired);
  }

  render() {
    const files = this.state.file ? [...this.state.file] : [];
    return (
      <LoadingScreen
        loading={this.state.isLoading}
        bgColor="#f1f1f1"
        spinnerColor="#9ee5f8"
        textColor="#676767"
        logoSrc="../../assets/images/Logo-bankNTT1.png"
        text=""
      >
        <div className="animated fadeIn">
          <ToastContainer />
          <div className="container-fluid">
            <div className="block-header">
              <div className="row clearfix">
                <div className="col-md-6 col-sm-12">
                  <h2>Tambah Penugasan</h2>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">KPI</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/">Job Desk</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Tambah Penugasan
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-12">
              <div className="card">
                <div className="body">
                  <form id="form">
                    <div className="row">
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <label
                            className="input-group-text"
                            htmlFor="inputGroupSelect01"
                            style={{ width: "150px" }}
                          >
                            Jenis Tugas
                          </label>
                        </div>
                        <select
                          className="custom-select"
                          id="inputGroupSelect01"
                          name="jenis"
                          onChange={(e) => this.handleChangeInput(e)}
                          value={this.state.jenis}
                        >
                          <option value="">
                            --Silahkan Pilih Jenis Tugas--
                          </option>
                          {this.state.data_jenis
                            .filter((e) => e.jenis_tugas !== "Tugas Rutin")
                            .map((d, i) => (
                              <option key={i} value={d.jenis_tugas}>
                                {d.jenis_tugas === "Tugas Utama"
                                  ? "Tugas Utama/RBB"
                                  : d.jenis_tugas === "Tugas Tambahan"
                                    ? "Tugas Khusus/Tambahan"
                                    : d.jenis_tugas === "Tugas Event"
                                      ? "Tugas Event"
                                      : "Bukan Tugas"}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text"
                            style={{ width: "150px" }}
                          >
                            Judul Tugas
                          </span>
                        </div>
                        <input
                          type="text"
                          name="judul"
                          className="form-control"
                          placeholder="Judul Tugas"
                          onChange={(e) => this.handleChangeInput(e)}
                        />
                      </div>

                      {this.state.jenis === "Tugas Event" ? (
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <label
                              className="input-group-text"
                              htmlFor="inputGroupSelect01"
                              style={{ width: "150px" }}
                            >
                              Tgl Mulai
                            </label>
                          </div>
                          <Flatpickr
                            // className='form-controll'
                            value={this.state.start}
                            options={{
                              disable: [],
                              minDate: "today",
                              altInput: true,
                              altFormat: "j F Y",
                              dateFormat: "Y-m-d",
                              onChange: (selectedDates, dateStr, instance) => {
                                this.setState(
                                  {
                                    start: dateStr,
                                  },
                                  () => {
                                    this.handleExp(
                                      this.state.start,
                                      this.state.end
                                    );
                                  }
                                );
                              },
                            }}
                            style={{ backgroundColor: `transparent` }}
                          />
                        </div>
                      ) : (
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <label
                              className="input-group-text"
                              htmlFor="inputGroupSelect01"
                              style={{ width: "150px" }}
                            >
                              Tgl Mulai
                            </label>
                          </div>
                          <Flatpickr
                            // className='form-controll'
                            value={this.state.start}
                            options={{
                              disable: [...this.state.hariLibur,
                              function (date) {
                                // return true to disable
                                return (
                                  date.getDay() === 0 || date.getDay() === 6
                                );
                              },
                              ],
                              locale: {
                                firstDayOfWeek: 1, // start week on Monday
                              },
                              minDate: "today",
                              altInput: true,
                              altFormat: "j F Y",
                              dateFormat: "Y-m-d",
                              onChange: (selectedDates, dateStr, instance) => {
                                this.setState(
                                  {
                                    start: dateStr,
                                  },
                                  () => {
                                    this.handleExp(
                                      this.state.start,
                                      this.state.end
                                    );
                                  }
                                );
                              },
                            }}
                            style={{ backgroundColor: `transparent` }}
                          />
                        </div>
                      )}

                      {this.state.jenis === "Tugas Event" ? (
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <label
                              className="input-group-text"
                              htmlFor="inputGroupSelect01"
                              style={{ width: "150px" }}
                            >
                              Tgl Akhir
                            </label>
                          </div>
                          <Flatpickr
                            // className='form-controll'
                            value={this.state.end}
                            options={{
                              disable: [],
                              minDate: "today",
                              altInput: true,
                              altFormat: "j F Y",
                              dateFormat: "Y-m-d",
                              onChange: (selectedDates, dateStr, instance) => {
                                this.setState(
                                  {
                                    end: dateStr,
                                  },
                                  () => {
                                    this.handleExp(
                                      this.state.start,
                                      this.state.end
                                    );
                                  }
                                );
                              },
                            }}
                            className="fp-override"
                          />
                        </div>
                      )
                        :
                        (
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <label
                                className="input-group-text"
                                htmlFor="inputGroupSelect01"
                                style={{ width: "150px" }}
                              >
                                Tgl Akhir
                              </label>
                            </div>
                            <Flatpickr
                              // className='form-controll'
                              value={this.state.end}
                              options={{
                                disable: [...this.state.hariLibur,
                                function (date) {
                                  // return true to disable
                                  return (
                                    date.getDay() === 0 || date.getDay() === 6
                                  );
                                },
                                ],
                                locale: {
                                  firstDayOfWeek: 1, // start week on Monday
                                },
                                minDate: "today",
                                altInput: true,
                                altFormat: "j F Y",
                                dateFormat: "Y-m-d",
                                onChange: (selectedDates, dateStr, instance) => {
                                  this.setState(
                                    {
                                      end: dateStr,
                                    },
                                    () => {
                                      this.handleExp(
                                        this.state.start,
                                        this.state.end
                                      );
                                    }
                                  );
                                },
                              }}
                              className="fp-override"
                            />
                          </div>
                        )
                      }
                      {
                        (
                          this.state.jenis === "Tugas Event" &&
                          this.state.session_credential.role !== 'admin'
                        ) ? (
                          <></>
                        ) : (
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                style={{ width: "150px" }}
                              >
                                Tgl Expired
                              </span>
                            </div>
                            <span className="ml-3 my-auto">
                              { this.state.expired !== "" && moment(this.state.expired).format("D MMMM YYYY") }
                            </span>
                          </div>
                        )
                      }
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <label
                            className="input-group-text"
                            htmlFor="inputGroupSelect01"
                            style={{ width: "150px" }}
                          >
                            Uraian Tugas
                          </label>
                        </div>
                        <textarea
                          className="form-control"
                          name="tugas"
                          value={this.state.tugas}
                          onChange={(e) => this.handleChangeInput(e)}
                          rows={7}
                          placeholder="Uraian Tugas"
                        />
                      </div>
                      <div className="input-group mb-3">
                        <div className="input-group mb-3">
                          {this.state.jenis !== "Tugas Event" ? (
                            // <select
                            //   multiple={true}
                            //   className="custom-select"
                            //   id="inputGroupSelect02"
                            //   name="pegawai"
                            //   onChange={(e) => this.handleChange(e)}
                            // >
                            //   <option defaultValue>
                            //     --Silahkan Pilih Pegawai--
                            //   </option>
                            //   {this.state.data_pegawai.map((pegawai, index) => {
                            //     return (
                            //       <option
                            //         key={index}
                            //         value={`${pegawai[6]};${pegawai[2]};${pegawai[3]};${pegawai[1]}`}
                            //       >
                            //         {`${pegawai[6]} (${pegawai[2]} - ${pegawai[1]})`}
                            //       </option>
                            //     );
                            //   })}
                            // </select>
                            // <ThemeProvider theme={darkTheme}>
                            <Autocomplete
                              multiple
                              sx={{ width: "100%" }}
                              options={this.state.data_pegawai}
                              value={this.state.pegawai}
                              onChange={(event, newValue) =>
                                this.setState({ pegawai: newValue })
                              }
                              getOptionLabel={(option) =>
                                `${option[6]} (${option[2]} - ${option[1]})`
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Pegawai"
                                  placeholder="Pilih Pegawai"
                                />
                              )}
                            />
                          ) : (
                            // </ThemeProvider>
                            <>
                              <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                  <label
                                    className="input-group-text"
                                    htmlFor="inputGroupSelect01"
                                    style={{ width: "150px" }}
                                  >
                                    Pegawai
                                  </label>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={`Tugas ini akan ditugaskan ke suluruh bawahan anda`}
                                  aria-describedby="basic-addon1"
                                  readOnly
                                />
                              </div>
                              <div className="input-group mb-3">
                                {/* <ThemeProvider theme={darkTheme}> */}
                                <Autocomplete
                                  multiple
                                  sx={{ width: "100%" }}
                                  options={this.state.data_pegawai}
                                  value={this.state.pegawai_dikecualikan}
                                  onChange={(event, newValue) =>
                                    this.setState({
                                      pegawai_dikecualikan: newValue,
                                    })
                                  }
                                  getOptionLabel={(option) =>
                                    `${option[6]} (${option[2]} - ${option[1]})`
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Pengecualian Pegawai"
                                      placeholder="Pilih pegawai yang tidak diikut sertakan"
                                    />
                                  )}
                                />
                                {/* </ThemeProvider> */}
                              </div>
                            </>
                          )}
                        </div>
                        {this.state.jenis !== "Tugas Event" ? (
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                style={{ width: "150px" }}
                              >
                                Input File <br />
                                {`(Opsional)`}
                              </span>
                            </div>
                            <input
                              type="file"
                              name="file"
                              ref={this.fileInput}
                              className="ml-3 my-auto"
                              multiple
                              onChange={(e) => this.handleFileChange(e)}
                            />
                            <br />
                            <ul>
                              {files.map((file, i) => (
                                <li key={i}>{file.name}</li>
                              ))}
                            </ul>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="col-md-12 col-sm-12 text-right">
                        <Button
                          className="btn btn-sm btn-secondary"
                          onClick={() => {
                            window.history.go(-1);
                          }}
                        >
                          Batal
                        </Button>{" "}
                        <Button
                          className="btn btn-sm btn-primary"
                          color="primary"
                          type="button"
                          onClick={(e) => this.handleSubmit(e)}
                          disabled={this.state.submitting}
                        >
                          Simpan
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingScreen>
    );
  }
}
export default CreateProjectList;
