import React from "react";
import { Link } from 'react-router-dom';
import Tooltip from "../common/toolTip";

const tableBodyDireksiAktif = (loading, data, {loadData}, {setModal})=>{
    if(data.length>0){
        return data && data.map((key, index)=>{
            return(
                <div className="col-lg-4 col-md-6">
                    <div className="card c_grid c_yellow">
                        <div className="body text-center">
                            <div className="circle">
                                <img className="rounded-circle" src="../assets/images/sm/user.png" alt="" />
                            </div>
                            <h6 className="mt-3 mb-0">{key.nama}</h6>
                            <span>{key.jabatan}</span>
                            <ul className="mt-3 list-unstyled d-flex justify-content-center">
                            </ul>
                            <button className="btn btn-default btn-sm mr-1" onClick={()=>{
                                loadData(key);
                                setModal('modal-direksi');
                            }}><i className="icon-pencil"></i></button>
                            <button className="btn btn-default btn-sm" onClick={()=>{
                                loadData(key);
                                setModal('modal-hapus-direksi');
                            }}><i className="icon-trash"></i></button>
                        </div>
                    </div>       
                </div>
            )
        })
    }else{
        return(
            <div className="col-lg-12">
                <div className="card">
                    <div className="body">
                        <div className="inline-editor">
                            <p className="m-b-0 text-center">Belum ada data</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const tableBodyDireksiTidakAktif = (loading, data, {loadData}, {setModal})=>{
    if(data.length>0){
        return data && data.map((key, index)=>{
            return(
                <tr>
                    <td className="width45">
                        <img src="../assets/images/xs/user.png" data-toggle="tooltip" data-placement="top" title="" alt="Avatar" className="w35 h35 rounded" data-original-title="Avatar Name" />
                    </td>
                    <td>
                        <h6 className="mb-0">{key.nama}</h6>
                        <span>{key.jabatan}</span>
                    </td>
                    <td>{key.npp}</td>
                    <td>{key.periode}</td>
                    <td>{key.tahun}</td>
                    <td></td>
                </tr>
            )
        })
    }else{
        return(
            <tr className="col-lg-12">
                <td colSpan={6}>
                    <div className="card">
                        <p className="m-b-0 text-center">Belum ada data</p>
                    </div>
                </td>
            </tr>
        )
    }
} 

export{
    tableBodyDireksiAktif,
    tableBodyDireksiTidakAktif
}