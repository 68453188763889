import React, { useState, useEffect } from "react";
import { CardWrapper, BreadcrumbWrapper } from "./PageComponents";
import { getCookie } from "../../../helpers/CookieManager";
import LoadingScreen from 'react-loading-screen';
import { AgGridWrapper } from "./TableComponent";
import { apiSKv1 } from "../../../configs/axios";
import Select from "react-select"
import { aoActivityTableHeader } from "./const";

const AoActivity = () => {
	// const token = window.sessionStorage.getItem("token");
	const token = getCookie("token");
	const tokenBody = token.split(".")[1];
	const result = JSON.parse(atob(tokenBody));

	const [isLoading, setIsLoading] = useState(false)
	const [sessionCred] = useState(result.sub);

	//* TABLE STATE
	const [tableData, setTableData] = useState([]);

	//* SELECT OPTIONS STATE
	const [branchOptions, setBranchOptions] = useState([])
	const [aoOptions, setAoOptions] = useState([])

	//* FORM STATE
	const [branchCode, setBranchCode] = useState(null)
	const [npp, setNpp] = useState(null)

	async function _getBranch() {
		apiSKv1.get("branches")
			.then(res => {
				const branchList = res.data.data.map((row) => {
					return { value: row.kode_cab, label: `${row.kode_cab} - ${row.nama_cab}` }
				})
				setBranchOptions(branchList)
			})
			.catch(err => {
				console.error(err);
			})
	}

	async function _getAO() {
		apiSKv1.get("account-officer")
			.then(res => {
				const aoList = res.data.data.map((row) => {
					return { value: row.npp, label: `${row.npp} - ${row.name}` }
				})
				setAoOptions(aoList)
			})
			.catch(err => {
				console.error(err);
			})
	}

	function _getData(action = null, key = null) {
		const params = {
			action: action,
			key: key,
		}

		setIsLoading(true)
		apiSKv1.get(`activities/${branchCode}/${npp}`, { params })
			.then(res => {
				const data = res.data.data
				setTableData(data)
			})
			.catch(err => console.error(err))
			.finally(() => setIsLoading(false))
	};

	useEffect(() => {
		_getBranch()
		_getAO()
	}, []);

	return (
		<LoadingScreen
			loading={isLoading}
			bgColor='#f1f1f1'
			spinnerColor='#9ee5f8'
			textColor='#676767'
			logoSrc="../../assets/images/Logo-bankNTT1.png"
			text=''
		>
			<div className="container-fluid">
				<div className="block-header">
					<div className="row clearfix">
						<div className="col-md-12 col-sm-12">
							<h2>Aktivitas AO</h2>
							<BreadcrumbWrapper path={["SAM Kredit", "Aktivitas AO"]} />
						</div>
					</div>
				</div>
				<div className="row clearfix">
					<div className="col-lg-12 col-md-12 col-sm-12">
						<CardWrapper>
							<form className="row" onSubmit={(e) => { e.preventDefault(); _getData(); }}>
								<div className="col-md-4">
									<span>Pilih Branch</span>
									<Select options={branchOptions} isClearable={true} onChange={(e) => setBranchCode(e?.value || null)} />
								</div>
								<div className="col-md-4">
									<span>Pilih Account Officer</span>
									<Select options={aoOptions} isClearable={true} onChange={(e) => setNpp(e?.value || null)} isDisabled={!branchCode} />
								</div>
								<div className="col-md-4">
									<span>&nbsp;</span>
									<button type="submit" className="btn btn-primary w-100" disabled={!branchCode || !npp || isLoading}>
										Lihat Data
									</button>
								</div>
							</form>
						</CardWrapper>
						<CardWrapper>
							<div className="row">
								<div className="col-sm-12 mb-3">
									<AgGridWrapper title="Tabel Aktivitas AO" header={aoActivityTableHeader} data={tableData} />
								</div>
							</div>
							<div className="row">
								<div className="col-sm-6">
									<button type="button" className="btn btn-primary w-100" onClick={() => _getData("prev", tableData[0].activityId)}>
										Previous
									</button>
								</div>
								<div className="col-sm-6">
									<button type="button" className="btn btn-primary w-100" onClick={() => _getData("next", tableData[-1].activityId)}>
										Next
									</button>
								</div>
							</div>
						</CardWrapper>
					</div>
				</div>
			</div >
		</LoadingScreen>
	);
};

export default AoActivity;
