import Login from './Authentication/login';
/*** KPI ***/
//import Pangkat from './Master/Pangkat';
import Jabatan from './Master/Jabatan';
import Kantor from './Master/Kantor';
import Pegawai from './Master/Pegawai';
import Periode from './Master/Periode';
import Direksi from './Master/Direksi';
import Struktur from './Master/Struktur';
import Skill from './Master/Skill'
import Kesalahan from './Master/Kesalahan'
import HariLibur from './Master/HariLibur';
import JudulTugasRutin from './Master/JudulTugasRutin';

//SAM Kredit
import AoActivity from './Worksheet/sam_kredit/AoActivity';
import SkLogs from './Worksheet/sam_kredit/SkLogs';

// Worksheet
import CreateProjectList from './Worksheet/create';
import DetailProjectList from './Worksheet/detail';
import DetailUserProjectList from './Worksheet/detail-user';
import AjukanTugas from './Worksheet/ajukan';
import Approval from './Worksheet/approval';
import UpdateTugas from './Worksheet/update';
import RevisiTugas from './Worksheet/revisi';

import HistoriUnitKerja from './Worksheet/HistoriUnitKerja'

import TugasHistori from './Worksheet/Tugas/TugasHistori';
import TugasPenugasan from './Worksheet/Tugas/TugasPenugasan';
import TugasPengajuan from './Worksheet/Tugas/TugasPengajuan';
import TugasProses from './Worksheet/Tugas/TugasProses';
import TugasSelesai from './Worksheet/Tugas/TugasSelesai';
import TugasSemua from './Worksheet/Tugas/TugasSemua';

import ReportHarian from './Worksheet/Report/ReportHarian'

// import Signup from './Authentication/signup';
// import ForgotPassword from './Authentication/forgotpassword';
import NotFound from './Authentication/404';
import Maintenance from './Authentication/maintenance';
// import Dashboard from './Dashboard/dashboard';
// import Dashboard2 from './Dashboard/dashboard2';
// import Dashboard3 from './Dashboard/dashboard3';
// import Dashboard4 from './Dashboard/dashboard4';
// import Dashboard5 from './Dashboard/dashboard5';
// import Dashboard6 from './Dashboard/dashboard6';
// import Dashboard7 from './Dashboard/dashboard7';
// import Dashboard8 from './Dashboard/dashboard8';
// import Dashboard9 from './Dashboard/dashboard9';
// import Dashboard10 from './Dashboard/dashboard10';
// import Dashboard11 from './Dashboard/dashboard11';
import Dashboard12 from './Dashboard/dashboard12';
// import FormAdvanced from './Form/formAdvanced';
// import FormBasic from './Form/formsBasic';
// import FormCropping from './Form/formsCropping';
// import FormDragdropupload from './Form/formDragdropupload';
// import FormsEditors from './Form/formsEditors';
// import FormSummernote from './Form/formSummernote';
// import FormValidation from './Form/formValidation';
// import FormWizard from './Form/formWizard';
// import ListView from './Contacts/ListView';
// import GridView from './Contacts/GridView';
// import Inbox from './Email/Inbox';
// import Compose from './Email/Compose';
// import Details from './Email/Details';
import Chat from './Messenger/chat';
import ProjectList from './Worksheet/ProjectList';
// import TicketList from './Projects/TicketList';
// import TicketDetails from './Projects/TicketDetails';
import Taskboard from './Worksheet/Taskboard';
// import TodoList from './Projects/TodoList';
// import Clients from './Projects/Clients';
// import Icons from './UI/Icons';
// import SimpleLine from './UI/SimpleLine';
// import Themify from './UI/Themify';
// import Bootstrap from './Components/Bootstrap';
// import Typography from './Components/Typography';
// import RangeSliders from './Components/RangeSliders';
// import Tabs from './Components/Tabs';
// import ProgressBars from './Components/ProgressBars';
// import Notifications from './Components/Notifications';
// import Modals from './Components/Modals';
// import Calendar from './Calendar/Calendar';
// import ApexChart from './Chart/apexChart';
// import jVector from './Map/jVector';
// import PageSocial from './Pages/pageSocial';
// import PageNews from './Pages/pageNews';
// import PageBlogPost from './Pages/pageBlogPost';
// import PageBlog from './Pages/pageBlog';
// import pageBlank from './Pages/pageBlank';
import pageProfile from './Pages/pageProfile';
// import pageUserList from './Pages/pageUserList';
// import PageTestimonials from './Pages/pageTestimonials';
// import PageInvoiceDetails from './Pages/pageInvoiceDetails';
// import PageInvoice from './Pages/pageInvoice';
// import PageTimeline from './Pages/pageTimeline';
// import PageSearchResult from './Pages/pageSearchResult';
// import PageGallery from './Pages/pageGallery';
// import PagePricing from './Pages/pagePricing';
// import PageComingSoon from './Pages/pageComingSoon';
// import Widgets from './Widgets/Widgets';
// import TableColor from './Table/tableColor';
// import TableDragger from './Table/tableDragger';
// import TableEditable from './Table/tableEditable';
// import TableFilter from './Table/tableFilter';
// import TableJqueryDatatable from './Table/tableJqueryDatatable';
// import TableNormal from './Table/tableNormal';
// import Colors from './Components/Colors';
// import Buttons from './Components/Buttons';
// import Dialogs from './Components/Dialogs';
// import ListGroup from './Components/ListGroup';
// import MediaObject from './Components/MediaObject';
// import Nestable from './Components/Nestable';

const Routes = [
    {
        path: "/dashboard12",
        name: 'dashboard12',
        exact: true,
        pageTitle: "Dashboard12",
        component: Dashboard12
    },
    {
        path: "/task-board",
        name: 'Taskboard',
        exact: true,
        pageTitle: "Taskboard",
        component: Taskboard
    },
    
    {
        path: "/page-profile",
        name: 'Page Profile',
        exact: true,
        pageTitle: "Page Profile",
        component: pageProfile
    },

    //Histori Unit Kerja
    {
        path: "/histori_unit_kerja",
        name: 'Histori Unit Kerja',
        exact: true,
        pageTitle: "Histori Unit Kerja",
        component: HistoriUnitKerja
    },

    // ROUTE TUGAS
    // {
    //     path: "/tugas",
    //     name: 'Seluruh Tugas',
    //     exact: true,
    //     pageTitle: "Seluruh Tugas",
    //     component: TugasSemua
    // },
    {
        path: "/tugas/histori",
        name: 'Histori Tugas',
        exact: true,
        pageTitle: "Histori Tugas",
        component: TugasHistori
    },
    {
        path: "/tugas/penugasan",
        name: 'Penugasan',
        exact: true,
        pageTitle: "Penugasan",
        component: TugasPenugasan
    },
    {
        path: "/tugas/pengajuan",
        name: 'Pengajuan Tugas',
        exact: true,
        pageTitle: "Pengajuan Tugas",
        component: TugasPengajuan
    },
    {
        path: "/tugas/dalam-proses",
        name: 'Tugas Dalam Proses',
        exact: true,
        pageTitle: "Tugas Dalam Proses",
        component: TugasProses
    },
    {
        path: "/tugas/selesai",
        name: 'Tugas Selesai',
        exact: true,
        pageTitle: "Tugas Selesai",
        component: TugasSelesai
    },
    {
        path: "/tugas/update/:id",
        name: 'updatetugas',
        exact: true,
        pageTitle: "UpdateTugas",
        component: UpdateTugas
    },
    {
        path: "/tugas/revisi/:id",
        name: 'revisitugas',
        exact: true,
        pageTitle: "RevisiTugas",
        component: RevisiTugas
    },
    {
        path: "/app-project-list",
        name: 'projectList',
        exact: true,
        pageTitle: "ProjectList",
        component: ProjectList
    },
    {
        path: "/create-project-list",
        name: 'createprojectList',
        exact: true,
        pageTitle: "CreateProjectList",
        component: CreateProjectList
    },
    {
        path: "/detail-project-list/:id",
        name: 'detailprojectList',
        exact: true,
        pageTitle: "DetailProjectList",
        component: DetailProjectList
    },
    {
        path: "/details-project-list/:id",
        name: 'detailuserprojectList',
        exact: true,
        pageTitle: "DetailUserProjectList",
        component: DetailUserProjectList
    },
    {
        path: "/ajukan-tugas",
        name: 'ajukantugas',
        exact: true,
        pageTitle: "AjukanTugas",
        component: AjukanTugas
    },
    {
        path: "/approval",
        name: 'approval',
        exact: true,
        pageTitle: "Approval",
        component: Approval
    },
    {
        path: "/skill",
        name: 'skill',
        exact: true,
        pageTitle: "Skill",
        component: Skill
    },
    {
        path: "/kesalahan",
        name: 'kesalahan',
        exact: true,
        pageTitle: "Kesalahan",
        component: Kesalahan
    },
    {
        path: "/hari-libur",
        name: 'hari-libur',
        exact: true,
        pageTitle: "Hari Libur",
        component: HariLibur
    },
    {
        path: "/judul-tugas-rutin",
        name: 'judul-tugas-rutin',
        exact: true,
        pageTitle: "Judul Tugas Rutin",
        component: JudulTugasRutin
    },
    {
        path: "/app-chat",
        name: 'chat',
        exact: true,
        pageTitle: "Chat",
        component: Chat
    },
    
    {
        path: "/",
        name: 'dashboard',
        exact: true,
        pageTitle: "Dashboard",
        component: Dashboard12
    },
    {
        path: "/login",
        name: 'login',
        exact: true,
        pageTitle: "Tables",
        component: Login
    },
    
    {
        path: "/maintenance",
        name: 'maintenance',
        exact: true,
        pageTitle: "Maintenance",
        component: Maintenance
    },
    {
        path: "/notfound",
        name: 'notfound',
        exact: true,
        pageTitle: "Tables",
        component: NotFound
    },
    {
        path: "/laporan/harian",
        name: 'Laporan Harian',
        exact: true,
        pageTitle: "Tables",
        component: ReportHarian
    },

    /** kpi ***/
    /*{
        path: '/pangkat',
        name: 'pangkat',
        exact: true,
        pageTitle: 'Pangkat',
        component: Pangkat
    },*/
    {
        path: '/direksi',
        name: 'direksi',
        exact: true,
        pageTitle: 'Direksi',
        component: Direksi
    },
    {
        path: '/struktur-organisasi',
        name: 'struktur-organisasi',
        exact: true,
        pageTitle: 'Struktur Organisasi',
        component: Struktur
    },
    {
        path: '/jabatan',
        name: 'jabatan',
        exact: true,
        pageTitle: 'Jabatan',
        component: Jabatan
    },{
        path: '/kantor',
        name: 'kantor',
        exact: true,
        pageTitle: 'Kantor',
        component: Kantor
    },{
        path: '/pegawai',
        name: 'Pegawai',
        exact: true,
        pageTitle: 'Pegawai',
        component: Pegawai
    },{
        path: '/periode',
        name: 'Periode',
        exact: true,
        pageTitle: 'Periode',
        component: Periode
    },
    // {
    //     path: '/aktivitas-ao',
    //     name: 'aktivitas-ao',
    //     exact: true,
    //     pageTitle: 'Aktivitas AO',
    //     component: AoActivity
    // },{
    //     path: '/logs/sk',
    //     name: 'logs-sk',
    //     exact: true,
    //     pageTitle: 'Log Request SAM Kredit',
    //     component: SkLogs
    // }
];

export default Routes;