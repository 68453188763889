import CryptoJS from 'crypto-js';

import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const defaultLimit = () =>{
    return 5;
};

const infoSession=(param)=>{
    return "";
};

const toastMessageCall = (msg, position, type) => {
    toast(msg, { type, autoClose: 3500, position, transition: Zoom });
};

const encrypt=(data)=>{
    if(!data.key){
        return "";
    };

    if(data.key=='.'){
        return "";
    };
    return CryptoJS.AES.encrypt(data, 'bankNTT@kpi').toString();
};

const formatBytes = (bytes, decimals = 2) =>{

	if(bytes){
	    if (bytes === 0) return '0 Bytes';

	    const k = 1024;
	    const dm = decimals < 0 ? 0 : decimals;
	    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	    const i = Math.floor(Math.log(bytes) / Math.log(k));

	    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}else{
		return '0 Bytes';
	}
}

export{
    defaultLimit,
    infoSession,
    toastMessageCall,
    encrypt
}
export default formatBytes