import React from "react";
import Tooltip from '../common/toolTip';

const tableBodyLevel = (loading, data,{loadDataLevel}, {setModal}) =>{
    return data && data.map((key, index)=>{
        return(
            <tr>
                <td className="w60">{index+1}</td>
                <td>{key.level}</td>
                <td style={{textAlign:'right'}}>
                    <Tooltip text="Edit" id="Edit" />
                    <button type="button" className="btn btn-sm btn-default" title="Edit" data-for="edit" data-tip onClick={()=>{
                        loadDataLevel(key);
                        setModal('modal-level');
                    }}><i className="icon-pencil"></i></button>
                    <Tooltip text="Hapus" id="Hapus" />
                    <button type="button" className="btn btn-sm btn-default" title="Hapus" data-for="hapus" data-tip onClick={()=>{
                        loadDataLevel(key);
                        setModal('modal-hapus-level');
                    }}><i className="icon-trash"></i></button>
                </td>
            </tr>
        )
    })
}

export {
    tableBodyLevel
}