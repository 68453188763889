import axios from 'axios';
import { getCookie } from '../helpers/CookieManager';

let headerz = null
const token = getCookie('token')
if (token) {
    headerz = {'Authorization' : `Bearer ${token}`, 'content-type': 'application/json'}
}

export const api = axios.create({
    //baseURL: 'http://localhost:8001/api/',
    baseURL:process.env.REACT_APP_API_URL,
    responseType: 'json',
    headers: headerz
})

export const apiWs = axios.create({
    //baseURL: 'http://localhost:8001/api/',
    baseURL:process.env.REACT_APP_API_URL_WS,
    responseType: 'json',
    headers: headerz
})

export const apiSKv1 = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL_SAM_KREDIT}v1/api/`,
    responseType: 'json',
    headers: {
        'content-type': 'application/json'
    }
})