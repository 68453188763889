import React, { useState, useEffect } from "react";
import Nestable from "react-nestable";
import { api, apiWs } from "../../../configs/axios";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  TabelWorksheet,
  JenisHandler,
  StatusHandler,
  DateFormatter,
} from "../TabelWorksheet";
import { getCookie } from "../../../helpers/CookieManager";
import LoadingScreen from 'react-loading-screen';

const Taskboard = () => {
  // const token = window.sessionStorage.getItem('token')
  const token = getCookie("token");
  const tokenBody = token.split(".")[1];
  const result = JSON.parse(atob(tokenBody));
  const [isLoading, setIsLoading] = useState(false)
  const [sessionCred, setSessionCred] = useState(result.sub);
  const [data, setData] = useState([]);
  const [tableHeader, setTableHeader] = useState([
    // { field: 'nama_pegawai', headerName: 'Nama Pegawai' },
    // { field: 'jabatan_pegawai', headerName: 'Jabatan Pegawai' },
    {
      field: "status",
      headerName: "Status",
      cellRendererFramework: (params) => (
        <StatusHandler status={params.data.status} />
      ),
    },
    { field: "judul", headerName: "Tugas" },
    {
      field: "jenis",
      headerName: "Jenis Tugas",
      cellRendererFramework: (params) => (
        <JenisHandler jenis={params.data.jenis} />
      ),
    },
    {
      field: "start",
      headerName: "Tanggal Penugasan",
      type: 'dateColumn',
      valueFormatter: (params) => DateFormatter(params.data.start),
    },
    {
      field: "end",
      headerName: "Tanggal Akhir",
      type: 'dateColumn',
      valueFormatter: (params) => DateFormatter(params.data.end),
    },
    {
      field: "exp",
      headerName: "Tanggal Expired",
      type: 'dateColumn',
      valueFormatter: (params) => DateFormatter(params.data.exp),
    },
    {
      field: "id_tugas",
      headerName: "Aksi",
      cellRendererFramework: (params) => (
        <Link
          className="btn btn-sm btn-info"
          to={`/detail-project-list/${params.data.id_tugas}`}
        >
          Detail
        </Link>
      ),
    },
  ]);

  const _getData = (a) => {
    setIsLoading(true)
    if (a) {
      var param = { a, npp: sessionCred.npp }
      apiWs.get("/tugas/selesai", {
        params: param
      })
        .then((res) => {
          var ar = [];
          if (res.data.data.length) {
            res.data.data.map((item) => {
              ar.push(JSON.parse(item));
            });
          }
          ar.sort(function (x, y) {
            return new Date(x.start) < new Date(y.start) ? 1 : -1;
          });
          // if (sessionCred.role === "admin")
          //   setData(
          //     ar.filter(
          //       (d) =>
          //         d.status === "Selesai" || d.status === "Selesai By Expired Date"
          //     )
          //   );
          // else
          //   setData(
          //     ar.filter(
          //       (d) =>
          //         d.npp_pegawai === sessionCred.npp &&
          //         (d.status === "Selesai" || d.status === "Selesai By Expired Date")
          //     )
          //   );
          setData(ar)
          setIsLoading(false)
        });
    } else {
      var param = { npp: sessionCred.npp }
      apiWs.get("/tugas/selesai", {
        params: param
      })
        .then((res) => {
          var ar = [];
          if (res.data.data.length) {
            res.data.data.map((item) => {
              ar.push(JSON.parse(item));
            });
          }
          ar.sort(function (x, y) {
            return new Date(x.start) < new Date(y.start) ? 1 : -1;
          });
          // if (sessionCred.role === "admin")
          //   setData(
          //     ar.filter(
          //       (d) =>
          //         d.status === "Selesai" || d.status === "Selesai By Expired Date"
          //     )
          //   );
          // else
          //   setData(
          //     ar.filter(
          //       (d) =>
          //         d.npp_pegawai === sessionCred.npp &&
          //         (d.status === "Selesai" || d.status === "Selesai By Expired Date")
          //     )
          //   );
          setData(ar)
          setIsLoading(false)
        });
    }
  };

  useEffect(() => {
    _getData();
  }, []);

  return (
    <LoadingScreen
      loading={isLoading}
      bgColor='#f1f1f1'
      spinnerColor='#9ee5f8'
      textColor='#676767'
      logoSrc="../../assets/images/Logo-bankNTT1.png"
      text=''
    >
      <div className="container-fluid">
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-12 col-sm-12">
              <h2>Tugas Selesai</h2>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Worksheet</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/">Tugas</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Selesai
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <button className="btn btn-info"
          style={{
            position: "absolute", /* becomes a layer */
            right: '50px', /* relative to its parent wrapper */
            top: '98px', /* relative to its parent wrapper */
            zIndex: 2,
          }}
          onClick={() => _getData('test')}
        >Tampilkan seluruh tugas</button>
        <TabelWorksheet
          tableData={data}
          tableHeader={tableHeader}
          tableTitle={`Tabel Tugas Selesai`}
        />
      </div>
    </LoadingScreen>
  );
};

export default Taskboard;
