import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toggleMegamenu, toggleSearchBar, toggleNotificationBar, setOffcanvas } from '../../actions/settingsAction';
import { Dropdown } from 'react-bootstrap';
import { deleteCookie, getCookie } from '../../helpers/CookieManager';
import { apiWs } from '../../configs/axios';
import { toastMessageCall } from '../../helpers/Utils';

const Header = ({ toggleMegamenu, isMegaMenu, toggleNotificationBar, toggleSearchBar, setOffcanvas, offcanvas }) => {

    // const token = window.sessionStorage.getItem('token');
    const storageWarningThreshold = 90
    const token = getCookie('token');
    const tokenBody = token.split('.')[1];
    const result = JSON.parse(atob(tokenBody))
    const [scrolled, setScrolled] = useState(0)
    const [jabatan, setJabatan] = useState(result.sub.role)
    const [modal, setModal] = useState(null)
    const [modalLoading, setModalLoading] = useState(false)
    const [storage, setStorage] = useState({
        total: "0",
        used: "0",
        free: "0",
        percent: 0
    })
    
    const scrollProgress = () => {
        const scrollPx = document.documentElement.scrollTop;
        const winHeightPx =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight;
        const scrolleda = `${scrollPx / winHeightPx * 100}%`;
        setScrolled(scrolleda)
    };
    window.addEventListener("scroll", scrollProgress);

    const progressContainerStyle = {
        background: "#f8bbd0",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
        height: "2px",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        zIndex: 99
    };

    const progressBarStyle = {
        // height: "2px",
        // background: "#e91e63",
        width: scrolled
    };

    const logOut = () => {
        // window.sessionStorage.clear()
        deleteCookie('token')
        window.location = '/'
    }

    const showStorageModal = () => {
        setModal("modal_storage")
        getStorageInfo()
    }

    const getStorageInfo = async () => {
        try {
            setModalLoading(true)
            const res = await apiWs.get("/disk_usage")
            setStorage(res.data.data)
        } catch (error) {
            toastMessageCall("Gagal mendapatkan informasi storage", "top-center", "error");
            console.error(error.response);
        } finally {
            setModalLoading(false)
        }
    }
    
    const closeModal = () => {
        setModal(null)
        setStorage({
            total: "0",
            used: "0",
            free: "0",
            percent: 0
        })
    }

    useEffect(() => {
        const getStorageInfoInit = async () => {
            try {
                const res = await apiWs.get("/disk_usage")
                if (res.data.data.percent >= storageWarningThreshold && jabatan === 'admin') {
                    toastMessageCall("Storage server hampir penuh!", "top-center", "warning");
                }
            } catch (error) {
                // SENGAJA DIKOSONGKAN
            }
        }
        
        if (jabatan === 'admin') {
            getStorageInfoInit()
        }
    }, [])
    

    return (
        <>
            <nav className="navbar top-navbar">
                <div className="container-fluid">
                    <div className="navbar-left">
                        <div className="navbar-btn">
                            <button type="button" className="btn-toggle-offcanvas" onClick={() => setOffcanvas(!offcanvas)}><i className="lnr lnr-menu fa fa-bars text-light"></i></button>
                        </div>
                        <ul className="nav navbar-nav">
                            <li>
                                <button className='btn btn-md btn-info ml-3' onClick={() => {window.location.reload(true)}}>Refresh &nbsp;<i className="fa fa-refresh"></i></button>
                            </li>
                            {/*<li className="dropdown">
                                <span className="dropdown-toggle icon-menu" data-toggle="dropdown">
                                    <i className="icon-envelope"></i>
                                    <span className="notification-dot bg-green">4</span>
                                </span>
                                <ul className="dropdown-menu right_chat email vivify fadeIn">
                                    <li className="header green">You have 4 New eMail</li>
                                    <li>
                                        <Link to="/">
                                            <div className="media">
                                                <div className="avtar-pic w35 bg-red"><span>FC</span></div>
                                                <div className="media-body">
                                                    <span className="name">James Wert <small className="float-right text-muted">Just now</small></span>
                                                    <span className="message">Update GitHub</span>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <a href="/">
                                            <div className="media">
                                                <div className="avtar-pic w35 bg-indigo"><span>FC</span></div>
                                                <div className="media-body">
                                                    <span className="name">Folisise Chosielie <small className="float-right text-muted">12min ago</small></span>
                                                    <span className="message">New Messages</span>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/">
                                            <div className="media">
                                                <img className="media-object " src="../assets/images/xs/avatar5.jpg" alt="avatar" />
                                                <div className="media-body">
                                                    <span className="name">Louis Henry <small className="float-right text-muted">38min ago</small></span>
                                                    <span className="message">Design bug fix</span>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/">
                                            <div className="media mb-0">
                                                <img className="media-object " src="../assets/images/xs/avatar2.jpg" alt="avatar" />
                                                <div className="media-body">
                                                    <span className="name">Debra Stewart <small className="float-right text-muted">2hr ago</small></span>
                                                    <span className="message">Fix Bug</span>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li className="dropdown">
                                <span className="dropdown-toggle icon-menu" data-toggle="dropdown">
                                    <i className="icon-bell"></i>
                                    <span className="notification-dot bg-azura">4</span>
                                </span>
                                <ul className="dropdown-menu feeds_widget vivify fadeIn">
                                    <li className="header blue">You have 4 New Notifications</li>
                                    <li>
                                        <a href="/">
                                            <div className="feeds-left bg-red"><i className="fa fa-check"></i></div>
                                            <div className="feeds-body">
                                                <h4 className="title text-danger">Issue Fixed <small className="float-right text-muted">9:10 AM</small></h4>
                                                <small>WE have fix all Design bug with Responsive</small>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/">
                                            <div className="feeds-left bg-info"><i className="fa fa-user"></i></div>
                                            <div className="feeds-body">
                                                <h4 className="title text-info">New User <small className="float-right text-muted">9:15 AM</small></h4>
                                                <small>I feel great! Thanks team</small>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/">
                                            <div className="feeds-left bg-orange"><i className="fa fa-question-circle"></i></div>
                                            <div className="feeds-body">
                                                <h4 className="title text-warning">Server Warning <small className="float-right text-muted">9:17 AM</small></h4>
                                                <small>Your connection is not private</small>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/">
                                            <div className="feeds-left bg-green"><i className="fa fa-thumbs-o-up"></i></div>
                                            <div className="feeds-body">
                                                <h4 className="title text-success">2 New Feedback <small className="float-right text-muted">9:22 AM</small></h4>
                                                <small>It will give a smart finishing to your site</small>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
    </li>*/}

                            {/* <li style={{ cursor: 'pointer' }}><span onClick={() => toggleMegamenu(!isMegaMenu)} className={`megamenu_toggle icon-menu`} title="Shortcut Menu">Shortcut</span></li> */}
                        </ul>
                    </div>

                    <div className="navbar-right">
                        <div id="navbar-menu">
                            <ul className="nav navbar-nav">
                                {jabatan === 'admin' && <li className='mr-1'><button className="btn btn-sm btn-success" onClick={showStorageModal}><i className="fa fa-database" /> Penggunaan Storage</button></li>}
                                {jabatan !== 'direksi' && jabatan !== 'admin' && <li className='mr-1'><Link to={`/ajukan-tugas`} className="btn btn-sm btn-success" title="Buat Penugasan"><i className="fa fa-pencil-square-o" /> Ajukan Tugas</Link></li>}
                                {jabatan !== 'pegawai' && <li><Link to={`/create-project-list`} className="btn btn-sm btn-success" title="Buat Penugasan"><i className="fa fa-pencil-square-o" /> Buat Penugasan</Link></li>}
                                {/* <li><span onClick={() => toggleSearchBar(true)} className="search_toggle icon-menu" title="Cari Pegawai"><i className="icon-magnifier"></i></span></li>
                                <li><span onClick={() => toggleNotificationBar(true)} className="right_toggle icon-menu" title="Pesan"><i className="icon-bubbles"></i><span className="notification-dot bg-pink">2</span></span></li> */}
                                <li>
								<Dropdown>
									<Dropdown.Toggle variant="success" id="dropdown-basic" className="user-name left_dropdown_btn">
                                    <span className="icon-menu"><i className="icon-user"></i></span>
									</Dropdown.Toggle>

									<Dropdown.Menu>
										<Link className="dropdown-item" to="/page-profile"><i className="icon-user mr-3"></i>Profile</Link>
										<Link className="dropdown-item" onClick={() => logOut()}><i className="icon-power mr-3"></i>Logout</Link>
									</Dropdown.Menu>
								</Dropdown>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="progress-container">
                    <div style={progressBarStyle} className="progress-bar" id="myBar"></div></div>
            </nav>
            <div
                className={`modal fade ${modal === "modal_storage" ? "d-block show" : ""
                }`}
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Penggunaan Storage {(storage.percent >= storageWarningThreshold) && <span className='text-danger'>- Storage Hampir Penuh</span>}</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={closeModal}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body text-center pt-4 mb-4">
                            {
                                modalLoading ? <i className="fa fa-spinner fa-spin"></i> :
                                <table className='w-100 text-left'>
                                    <tr>
                                        <th>Total</th>
                                        <td>:</td>
                                        <td>{storage.total}</td>
                                    </tr>
                                    <tr>
                                        <th>Digunakan</th>
                                        <td>:</td>
                                        <td>{storage.used}</td>
                                    </tr>
                                    <tr>
                                        <th>Sisa</th>
                                        <td>:</td>
                                        <td><span className={(storage.percent >= storageWarningThreshold && `text-danger`)}>{storage.free}</span></td>
                                    </tr>
                                    <tr>
                                        <th>Persentase Penggunaan</th>
                                        <td>:</td>
                                        <td><span className={(storage.percent >= storageWarningThreshold && `text-danger`)}>{storage.percent}%</span></td>
                                    </tr>
                                </table>
                            }
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-round btn-primary"
                                data-dismiss="modal"
                                onClick={getStorageInfo}
                            >
                                Muat Ulang
                            </button>
                            <button
                                type="button"
                                className="btn btn-round btn-default"
                                data-dismiss="modal"
                                onClick={closeModal}
                            >
                                Tutup
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = state => ({
    isMegaMenu: state.settings.isMegaMenu,
    offcanvas: state.settings.offcanvas,
})

const mapDispatchToProps = dispatch => ({
    toggleMegamenu: (e) => dispatch(toggleMegamenu(e)),
    toggleSearchBar: (e) => dispatch(toggleSearchBar(e)),
    toggleNotificationBar: (e) => dispatch(toggleNotificationBar(e)),
    setOffcanvas: (e) => dispatch(setOffcanvas(e)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Header);