import React, { useState, useEffect } from "react";
import { api, apiWs } from "../../../configs/axios";
import { Link } from "react-router-dom";
import {
  TabelWorksheet,
  JenisHandler,
  StatusHandler,
  DateFormatter,
} from "../TabelWorksheet";
import swal from "sweetalert";
import "flatpickr/dist/themes/airbnb.css";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { getCookie } from "../../../helpers/CookieManager";
import LoadingScreen from 'react-loading-screen';
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { toastMessageCall } from "../../../helpers/Utils";

const ReportHarian = () => {
  // const token = window.sessionStorage.getItem("token");
  const token = getCookie("token");
  const tokenBody = token.split(".")[1];
  const result = JSON.parse(atob(tokenBody));

  const [sessionCred, setSessionCred] = useState(result.sub);
  const [isLoading, setIsLoading] = useState(false)
  const [comboJenisTugas, setComboJenisTugas] = useState([]);
  const [select, setSelect] = useState({
    jenis: "",
    status: "",
  });

  const [dataPegawai, setDataPegawai] = useState([])
  const [pegawai, setPegawai] = useState([])
  const [NppPegawai, setNppPegawai] = useState([])

  const [data, setData] = useState(null);
  const [tglStart, setTglStart] = useState(null);
  const [tglEnd, setTglEnd] = useState(null);

  const [tableHeader, setTableHeader] = useState([
    { field: "id_tugas", headerName: "ID Tugas" },
    { field: "npp", headerName: "NPP" },
    { field: "nama_pegawai", headerName: "Nama Pegawai" },
    { field: "jabatan_pegawai", headerName: "Jabatan Pegawai" },
    { field: "kantor", headerName: "Kantor" },
    { field: "kantor_induk", headerName: "Kantor Induk" },
    { field: "judul", headerName: "Tugas" },
    {
      field: "jenis",
      headerName: "Jenis Tugas",
      cellRendererFramework: (params) => (
        <JenisHandler jenis={params.data.jenis} />
      ),
    },
    { field: "tugas", headerName: "Uraian" },
    {
      field: "tgl_diajukan",
      headerName: "Tanggal Pengajuan",
      type: 'dateColumn',
      valueFormatter: (params) => DateFormatter(params.data.tgl_diajukan),
    },
    {
      field: "tgl_diterima",
      headerName: "Tanggal Persetujuan",
      type: 'dateColumn',
      valueFormatter: (params) => DateFormatter(params.data.tgl_diterima),
    },
    { field: "nama_atasan", headerName: "Pejabat yang Menyetujui" },
    { field: "jabatan_atasan", headerName: "Jabatan yang Menyetujui" },
    {
      field: "start",
      headerName: "Tanggal Penugasan",
      type: 'dateColumn',
      valueFormatter: (params) => DateFormatter(params.data.start),
    },
    {
      field: "end",
      headerName: "Tanggal Akhir",
      type: 'dateColumn',
      valueFormatter: (params) => DateFormatter(params.data.end),
    },
    {
      field: "exp",
      headerName: "Tanggal Expired",
      type: 'dateColumn',
      valueFormatter: (params) => DateFormatter(params.data.exp),
    },
    {
      field: "tgl_selesai",
      headerName: "Tanggal Disetujui",
      type: 'dateColumn',
      valueFormatter: (params) => DateFormatter(params.data.tgl_selesai),
    },
    { field: "triwulan", headerName: "Triwulan" },
    { field: "triwulan_tahun", headerName: "Tahun" },
    {
      field: "status",
      headerName: "Status",
      cellRendererFramework: (params) => (
        <StatusHandler status={params.data.status} />
      ),
    },
    { field: "nilai", headerName: "Score" },
    { field: "skill", headerName: "Skill" },
    { field: "jumlah", headerName: "Jumlah" },
    { field: "kesalahan", headerName: "Kesalahan" },
    { field: "jumlah_kesalahan", headerName: "Jumlah Kesalahan/Koreksi" },
    {
      field: "id_tugas",
      headerName: "Aksi",
      minWidth: 150,
      maxWidth: 250,
      flex: 2,
      cellRendererFramework: (params) => (
        <>
          <Link
            className="btn btn-sm btn-info"
            to={`/detail-project-list/${params.data.id_tugas}`}
          >
            Detail
          </Link>{" "}
          {params.data.status !== "Selesai" && (
            <button
              className="btn btn-sm btn-danger"
              onClick={() => swalDeleteTugas(params.data.id_tugas)}
            >
              Hapus
            </button>
          )}
        </>
      ),
    },
  ]);

  function swalDeleteTugas(id_tugas) {
    let swalOptions = {
      title: "Apakah anda yakin untuk menghapus tugas ini?",
      icon: "warning",
      closeOnClickOutside: false,
      dangerMode: true,
      content: {
        element: "input",
        attributes: {
          placeholder: "Masukkan alasan penghapusan tugas",
          type: "text",
          required: "True",
        },
      },
      buttons: {
        cancel: {
          text: "Batal",
          value: false,
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: "Hapus",
          value: true,
          visible: true,
          closeModal: false,
        },
      },
    };

    swal(swalOptions).then((alasan) => {
      if (alasan === "") {
        swalOptions = {
          title: "Pastikan alasan penghapusan tugas terisi!",
          icon: "error",
          closeOnClickOutside: false,
          buttons: "Tutup",
        };
        swal(swalOptions);
      } else if (alasan) {
        apiWs
          .delete(`/tugas/${id_tugas}`, { data: alasan })
          .then((res) => {
            swalOptions = {
              title: "Tugas Berhasil Dihapus",
              icon: "success",
              closeOnClickOutside: false,
              buttons: "Tutup",
            };

            swal(swalOptions);

            setData(lastState => lastState.filter(item => item.id_tugas !== id_tugas))
          })
          .catch((err) => {
            swalOptions = {
              title: "Tugas Gagal Dihapus",
              icon: "error",
              closeOnClickOutside: false,
              buttons: "Tutup",
            };

            swal(swalOptions);
          })
      }
    });
  }

  // function _getDataBackend() {
  //   setIsLoading(true)
  //   const params = {
  //     start: tglStart,
  //     end: tglEnd,
  //     jenis_tugas: select.jenis,
  //     status_tugas: select.status
  //   }
  //   apiWs.get("/tugas/report", { params })
  //     .then((res) => {
  //       if (res.data.data.length) {
  //         setData(res.data.data)
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error.response);
  //     })
  //     .finally(() => {
  //       setIsLoading(false)
  //     })
  // }

  async function _getData() {
    setIsLoading(true)
    let { dataTugas, list_id_tugas } = await _getTugas(
      tglStart,
      tglEnd,
      select.jenis,
      select.status,
      NppPegawai
    );
    let dataSkill = await _getSkill(list_id_tugas);
    let dataKesalahan = await _getKesalahan(list_id_tugas);

    let dataReport = [];
    dataTugas.forEach((report, index) => {
      // PROSES SKILL
      let tugas_by_skill = dataSkill.filter(
        (row) => row[0] === report.id_tugas
      );
      let skill_tugas = [];
      tugas_by_skill.map((skill) => {
        skill_tugas.push(skill[1]);
      });
      skill_tugas = skill_tugas.join(", ");

      // PROSES KESALAHAAN
      let tugas_by_kesalahan = dataKesalahan.filter(
        (row) => row[0] === report.id_tugas
      );
      let kesalahan_tugas = [];
      tugas_by_kesalahan.map((kesalahan) => {
        kesalahan_tugas.push(kesalahan[1]);
      });
      const kesalahan_count = kesalahan_tugas.length;
      kesalahan_tugas = kesalahan_tugas.join(", ");

      let reportRow = {
        id_tugas: report.id_tugas,
        start: report.start,
        end: report.end,
        exp: report.exp,
        jenis: report.jenis,
        judul: report.judul,
        tugas: report.tugas,
        status: report.status,
        nilai: report.nilai,
        last_id: report.last_id,
        nominal: report.nominal,
        jml_atasan: report.jml_atasan,
        jml_koreksi: report.jml_koreksi,
        tgl_diajukan: report.cts,
        tgl_diterima: report.tgl_diterima,
        tgl_selesai: report.tgl_selesai,
        nama_atasan: report.nama_atasan,
        jabatan_atasan: report.jabatan_atasan,
        npp: report.npp_pegawai,
        nama_pegawai: report.nama_pegawai,
        jabatan_pegawai: report.jabatan_pegawai,
        kantor: report.kantor,
        kantor_induk: report.kantor_induk,
        skill: skill_tugas,
        jumlah: (report.nominal !== report.jml_atasan && report.status === "Selesai" ? report.jml_atasan : report.nominal),
        kesalahan: (report.jenis === "Tugas Rutin" && report.jml_koreksi ? 'Koreksi' : kesalahan_tugas),
        triwulan: report.triwulan,
        triwulan_tahun: report.triwulan_tahun,
        jumlah_kesalahan:
          report.jenis !== "Tugas Rutin" ? kesalahan_count : report.jml_koreksi,
      };
      // console.log(reportRow);
      dataReport.push(reportRow);
    });

    setData(dataReport);
    setIsLoading(false)
  }

  async function _getTugas(start, end, jenis, status, npp) {
    //* GET TUGAS
    const data = {
      start,
      end,
      npp
    }
    const res = await apiWs.post("/tugas/report", data)
    let dataTugas = [];
    let list_id_tugas = []
    if (res.data.data.length) {
      res.data.data.forEach((item) => {
        const jsonItem = JSON.parse(item)
        dataTugas.push(jsonItem);
        list_id_tugas.push(jsonItem.id_tugas);
      });
    }

    //* GET HISTORY TUGAS
    const history = await _getHistory(list_id_tugas)

    //* BENTUK DATA TUGAS
    dataTugas.forEach((tugas) => {
      const tgl_dikerjakan = history.filter(
        (row) => row[0] === tugas.id_tugas && row[2] === "Dikerjakan"
      );
      tgl_dikerjakan.sort((x, y) =>
        new Date(x.cts) < new Date(y.cts) ? -1 : 1
      );

      const tgl_diterima = history.filter(
        (row) => row[0] === tugas.id_tugas && (row[2] === "Diterima" || row[2] === "Diterima By System")
      );
      if (tgl_diterima[0]) {
        tugas.tgl_diterima = moment(tgl_diterima[0][1]).format(
          "YYYY-MM-DD"
        );
      } else if (tgl_dikerjakan.length <= 0) {
        tugas.tgl_diterima = null;
      } else {
        tugas.tgl_diterima = moment(tgl_dikerjakan[0][1]).format(
          "YYYY-MM-DD"
        );
      }

      const tgl_selesai = history.filter(
        (row) => row[0] === tugas.id_tugas && (row[2] === "Selesai" || row[2] === "Selesai By Expired Date")
      );
      if (tgl_selesai[0]) {
        tugas.tgl_selesai = moment(tgl_selesai[0][1]).format(
          "YYYY-MM-DD"
        );
      } else {
        tugas.tgl_selesai = null;
      }
    })

    //* FILTERING DATA
    dataTugas = dataTugas
      .filter((d) => d.status !== "Deleted")
      .filter(
        (d) =>
          moment(d.cts).format("YYYY-MM-DD") >= start &&
          moment(d.cts).format("YYYY-MM-DD") <= end
      );

    if (status) {
      dataTugas = dataTugas.filter((d) => d.status === status);
    }

    if (jenis) {
      dataTugas = dataTugas.filter((d) => d.jenis === jenis);
    }

    return {
      dataTugas,
      list_id_tugas,
    }
  }

  async function _getHistory(list_id_tugas) {
    const data = {
      list_id_tugas
    }
    const res = await apiWs.post("/history-tugas", data)
    let dataHistory = [];
    if (res.data.length) {
      res.data.forEach((item) => dataHistory.push(item));
    }
    // console.log(dataHistory);
    return dataHistory
  }

  async function _getSkill(list_id_tugas) {
    const data = {
      list_id_tugas
    }
    const res = await apiWs.post("/skill-tugas", data)
    let dataSkill = [];
    if (res.data.length) {
      res.data.forEach((item) => dataSkill.push(item));
    }
    // console.log(dataSkill);
    return dataSkill
  }

  async function _getKesalahan(list_id_tugas) {
    const data = {
      list_id_tugas
    }
    const res = await apiWs.post("/kesalahan-tugas", data)
    let dataKesalahan = [];
    if (res.data.length) {
      res.data.forEach((item) => dataKesalahan.push(item))
    }
    // console.log(dataKesalahan);
    return dataKesalahan
  }

  // function twHandler(tw) {
  //   var thisYear = new Date();
  //   var thisYear = thisYear.getFullYear();
  //   switch (tw) {
  //     case `0`:
  //       setTglStart(`${thisYear}-01-01`);
  //       setTglEnd(`${thisYear}-12-31`);
  //       break;
  //     case `1`:
  //       setTglStart(`${thisYear}-01-01`);
  //       setTglEnd(`${thisYear}-03-31`);
  //       break;
  //     case `2`:
  //       setTglStart(`${thisYear}-04-01`);
  //       setTglEnd(`${thisYear}-06-30`);
  //       break;
  //     case `3`:
  //       setTglStart(`${thisYear}-07-01`);
  //       setTglEnd(`${thisYear}-09-30`);
  //       break;
  //     case `4`:
  //       setTglStart(`${thisYear}-10-01`);
  //       setTglEnd(`${thisYear}-12-31`);
  //       break;
  //     default:
  //       setTglStart(``);
  //       setTglEnd(``);
  //       break;
  //   }
  // }

  // function _handleSubmit(e) {
  //   e.preventDefault();
  //   // console.log(tglStart, tglEnd);
  //   // return false;
  //   _getData();
  //   // _getDataBackend()
  // }

  function _handleChangeInput(e) {
    const name = e.target.name;
    setSelect({
      ...select,
      [name]: e.target.value,
    });
  }

  function _loadJenisTugas() {
    apiWs.get("/jenis-tugas").then((res) => {
      if (res.data.result) {
        let ar = [];
        if (res.data.data.length) {
          res.data.data.map((item) => {
            ar.push(JSON.parse(item));
          });
        }
        setComboJenisTugas(ar);
      }
    });
  }

  function _loadDataPegawai() {
    var param = {
      key: 'true~$~~$~~$~~$~',
      limit: 5,
      start_row: 0
    };
    api.get('/pegawai', { params: param }).then(res => {
      if (!res.data.result) {
        toastMessageCall(res.data.message, 'top-center', 'warning');
        return false;
      };
      var ar = [];
      if (res.data.data.length) {
        res.data.data.map((item, i, row) => {
          ar.push(JSON.parse(item[0]));
        });
      };

      setDataPegawai(ar);
    }).catch(err => {
      console.error(err);
      toastMessageCall('Gagal terhubung dengan backend. Error : ' + err);
    })
  };

  useEffect(() => {
    _loadJenisTugas();
    _loadDataPegawai();
  }, []);

  return (
    <LoadingScreen
      loading={isLoading}
      bgColor='#f1f1f1'
      spinnerColor='#9ee5f8'
      textColor='#676767'
      logoSrc="../../assets/images/Logo-bankNTT1.png"
      text=''
    >
      <div className="container-fluid">
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-12 col-sm-12">
              <h2>Laporan Harian</h2>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Worksheet</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/">Laporan</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Harian
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div className="row clearfix">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="card">
              <div className="body taskboard b-cyan planned_task">
                <h6 className="font300 mb-3">Export Report Harian</h6>
                <form onSubmit={(e) => { e.preventDefault(); _getData(); }}>
                  {/* <div className="col">
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <label className="input-group-text">Periode</label>
                          <select
                            onChange={(e) => twHandler(e.target.value)}
                            className="custom-select form-control"
                          >
                            <option value={``}>-- Pilih Periode --</option>
                            <option value={`0`}>Seluruh Tugas</option>
                            <option value={`1`}>Triwulan 1</option>
                            <option value={`2`}>Triwulan 2</option>
                            <option value={`3`}>Triwulan 3</option>
                            <option value={`4`}>Triwulan 4</option>
                          </select>
                        </div>
                      </div>
                    </div> */}
                  <div className="col">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend w-100">
                        <span
                          className="input-group-text"
                          style={{ width: "250px" }}
                        >
                          Tanggal Mulai
                        </span>
                        <Flatpickr
                          value={tglStart}
                          options={{
                            altInput: true,
                            altFormat: "j F Y",
                            dateFormat: "Y-m-d",
                            onChange: (selectedDates, dateStr, instance) => {
                              setTglStart(dateStr);
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend w-100">
                        <span
                          className="input-group-text"
                          style={{ width: "250px" }}
                        >
                          Tanggal Selesai
                        </span>
                        <Flatpickr
                          value={tglEnd}
                          options={{
                            altInput: true,
                            altFormat: "j F Y",
                            dateFormat: "Y-m-d",
                            onChange: (selectedDates, dateStr, instance) => {
                              setTglEnd(dateStr);
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend w-100">
                        <span
                          className="input-group-text"
                          style={{ width: "250px" }}
                        >
                          Jenis Tugas
                        </span>
                        <select
                          className="custom-select"
                          id="inputGroupSelect01"
                          name="jenis"
                          onChange={(e) => _handleChangeInput(e)}
                        >
                          <option value="">Seluruh Jenis tugas</option>
                          {comboJenisTugas.map((e, i) => (
                            <option key={i} value={e.jenis_tugas}>
                              {e.jenis_tugas}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend w-100">
                        <span
                          className="input-group-text"
                          style={{ width: "250px" }}
                        >
                          Status Tugas
                        </span>
                        <select
                          className="custom-select"
                          id="inputGroupSelect01"
                          name="status"
                          onChange={(e) => _handleChangeInput(e)}
                        >
                          <option value="">Seluruh Status Tugas</option>
                          <option value="Menunggu">Menunggu</option>
                          <option value="Diterima">Diterima</option>
                          <option value="Diterima By System">Diterima By System</option>
                          <option value="Ditolak">Ditolak</option>
                          <option value="Dikerjakan">Dikerjakan</option>
                          <option value="Revisi">Revisi</option>
                          <option value="Tidak Dikerjakan">
                            Tidak Dikerjakan
                          </option>
                          <option value="Tidak Diselesaikan">
                            Tidak Diselesaikan
                          </option>
                          <option value="Selesai">Selesai</option>
                          <option value="Selesai By Expired Date">
                            Selesai By Expired Date
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend w-100">
                        <Autocomplete
                          multiple
                          sx={{ width: "100%" }}
                          options={dataPegawai}
                          value={pegawai}
                          onChange={(event, newValue) => {
                            setPegawai(newValue)
                            const npp = newValue.map(item => item.npp);
                            setNppPegawai(npp)
                          }}
                          getOptionLabel={(option) =>
                            `${option.nama} (${option.jabatan} - ${option.kantor})`
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Pegawai"
                              placeholder="Pilih Pegawai"
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <button type="submit" className="btn btn-sm btn-info w-100">
                      Lihat Report
                    </button>
                  </div>
                  <div className="row"></div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {!data ? (
          <h5>Silakan pilih tanggal report</h5>
        ) : data.length === 0 ? (
          <h5>Tidak ada data untuk ditampilkan</h5>
        ) : (
          <TabelWorksheet
            tableData={data}
            tableHeader={tableHeader}
            tableTitle={`Tabel Report Harian`}
            tglReport={`${tglStart} - ${tglEnd}`}
          />
        )}
      </div>
    </LoadingScreen>
  );
};

export default ReportHarian;
