import React, { Component, useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Menu from "./Menu";
import Header from "./Header";
import ThemeSetting from "./ThemeSetting";
import Searchbar from "./Searchbar";
import Rightbar from "./Rightbar";
import Megamenu from "./Megamenu";
import Routes from "../Route";
import { useIdleTimer } from "react-idle-timer";
import { deleteCookie } from "../../helpers/CookieManager";

// export default class Layout extends Component {

//   render() {
//     return (
//       <>
//         <ThemeSetting />
//         <div className="overlay" />
//         <div id="wrapper">
//           <Header />
//           <Searchbar />
//           <Megamenu />
//           <Rightbar />
//           <Menu {...this.props} />
//           <div id="main-content">
//             <Switch>
//               {Routes.map((layout, i) => {
//                 return (
//                   <Route
//                     key={`r${i}`}
//                     exact={layout.exact}
//                     path={layout.path}
//                     component={layout.component}
//                   ></Route>
//                 );
//               })}
//             </Switch>
//           </div>
//         </div>
//       </>
//     );
//   }
// }

export default function Layout(props) {
  const [state, setState] = useState("Active");

  const onIdle = () => {
    // setState("Idle");
    // window.sessionStorage.clear()
    deleteCookie('token')
    window.location = '/'
  };

  const onActive = () => {
    // setState("Active");
  };

  const idleTimer = useIdleTimer({
    onIdle,
    onActive,
    timeout: (1000 * 60 * 10),
  });

  // useEffect(() => {
  //   console.log(state);
  // }, [state])
  

  return (
    <>
      <ThemeSetting />
      <div className="overlay" />
      <div id="wrapper">
        <Header />
        <Searchbar />
        <Megamenu />
        <Rightbar />
        <Menu {...props} />
        <div id="main-content">
          <Switch>
            {Routes.map((layout, i) => {
              return (
                <Route
                  key={`r${i}`}
                  exact={layout.exact}
                  path={layout.path}
                  component={layout.component}
                ></Route>
              );
            })}
          </Switch>
        </div>
      </div>
    </>
  );
}
